import React from "react";
import { RouteComponentProps } from "react-router";
import AuctionCollectionContainer from "../containers/AuctionCollectionContainer";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
const $ = Store.data;

interface MatchParams {
	anzahl: string;
	seite: string;
}
interface Props extends RouteComponentProps<MatchParams> {}
interface State {
	userId: number;
}

export default class AuctionCollectionView extends Observer<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			userId: $.user.id
		};
	}

	componentDidMount() {
		Store.attach(this);
		$.url.anzahl = Number(this.props.match.params.anzahl);
		$.url.seite = Number(this.props.match.params.seite);
		$.auctionCollection.readApi(Store, $.url.anzahl, $.url.seite, '0');
	}

	componentDidUpdate(prevProps: Props) {
		if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
			$.url.anzahl = Number(this.props.match.params.anzahl);
			$.url.seite = Number(this.props.match.params.seite);
			$.auctionCollection.readApi(
				Store,
				Number(this.props.match.params.anzahl),
				Number(this.props.match.params.seite),
				'0',
			);
		}
	}

	update() {
		this.setStateNStore({
			userId: $.user.id
		});
	}

	render() {
		if (this.state.userId === 0) {
			$.user.checkUser(Store);
		}

		return (
			<div>
				<code className="component_info">{this.constructor.name}</code>
				{this.state.userId !== 0 && <AuctionCollectionContainer />}
			</div>
		);
	}
}
