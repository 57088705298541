import UserCollection from "../models/UserCollection";
import User from "../models/User";
import React from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Product from "../models/Product";
import Observer from "../observerstore/Observer";
import Store from "../observerstore/Store";
const $ = Store.data;

type Props = {};

type State = {
	product: Product;
	userCollection: UserCollection;
};

export default class UserSelectContainerDetail extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			product: new Product(),
			userCollection: new UserCollection(),
		};
		this.handleChange = this.handleChange.bind(this);
		this.getCurrentUser = this.getCurrentUser.bind(this);
	}

	componentDidMount() {
		Store.attach(this);
		$.userCollection.readApi(Store);
	}

	public update() {
		this.setStateNStoreForce({
			product: $.product,
			userCollection: $.userCollection,
		});
	}

	private handleChange(event: any) {
		const value: number = Number(event.target.value);
		$.product.besitzer = value;
		this.update();
	}

	private getCurrentUser(): User | undefined {
		return this.state.userCollection.get(this.state.product.besitzer);
	}

	public renderSelect() {
		return (
			<>
				<code className="component_info">{this.constructor.name}</code>
				<Form.Group>
					<Form.Control
						as="select"
						onChange={this.handleChange}
						value={String(this.state.product.besitzer)}
					>
						{this.state.userCollection.collection.map(
							(user: User, i: any) => {
								return (
									<option
										key={i}
										value={user.id}
									>{`${user.number} – ${user.vorname} ${user.nachname}`}</option>
								);
							}
						)}
					</Form.Control>
				</Form.Group>
			</>
		);
	}

	render() {
		let user: User | undefined = this.getCurrentUser();
		return (
			<>
				<code className="component_info">{this.constructor.name}</code>
				{user && (
					<Card style={{marginTop: '20px', marginBottom: '20px', backgroundColor: '#eee'}}>
						<Card.Body>
							<Card.Text>
									{`Einlieferer: ${user.vorname} ${user.nachname}`}
									<br />
									{`Kundennummer: ${user.number}`}
							</Card.Text>
						</Card.Body>
					</Card>
				)}
			</>
		);
	}
}
