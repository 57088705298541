import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import SourceProductContainer from "../containers/SourceProductContainer";
import User from "../models/User";
import Product from "../models/Product";
import ProductContainer from "../containers/ProductContainer";
import Observer from "../observerstore/Observer";
import Store from "../observerstore/Store";

type Props = {
	match: any;
};

type State = {
	user: User;
	show: boolean;
	prevProduct: Product;
	nextProduct: Product;
};

export default class ProductView extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			user: new User(),
			show: false,
			prevProduct: new Product(),
			nextProduct: new Product(),
		};
	}

	componentDidMount() {
		Store.attach(this);
		this.scrollTop();
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.scrollTop();
		}
	}

	scrollTop() {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}

	render() {
		return (
			<>
				<code className="component_info">ProductView.js</code>
				<Alert variant="success" show={this.state.show}>
					Update war erfolgreich
				</Alert>
				<Container>
					<Row>
						{this.state.user.admin === true && <Col sm={3} />}
						<Col sm={6} className="white">
							<ProductContainer id={this.props.match.params.id} />
						</Col>
						{this.state.user.admin !== true && (
							<Col sm={6} className="grey">
								<SourceProductContainer />
							</Col>
						)}
					</Row>
				</Container>
			</>
		);
	}
}
