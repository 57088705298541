import React from "react";
import Container from "react-bootstrap/Container";
import Observer from "../observerstore/Observer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProductsContainer from "../containers/ProductsContainer";

interface Props {};

interface State {
};

export default class ProductsView extends Observer<Props, State> {

	render() {
		return (
			<>
				<code className="component_info">ProductsView.js</code>
				<Container>
					<Row>
						<Col sm={3} />
						<Col sm={6} className="white">
							<ProductsContainer
							/>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}
