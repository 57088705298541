import User from "../models/User";
import {REQUESTURL} from "../constants/urls";

export default class UserCollection {

	collection: User[];

	constructor() {
		this.collection = [];
	}

	readApi(Store: any) {
		const _this = this;
		const url = REQUESTURL + "api/users";
		const token = localStorage.getItem('token');

		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${token}`
			}
		})
			.then(function (response) {
				if (response.ok) return response.json();
				console.error("Die Rückgabe ist nicht ok");
				window.location.href = "#/";
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.then(function (json) {
				_this.translateApi(json);
				Store.notify();
			})
			.catch(function (err) {
				console.log(`error: ${err}`);
			});
	}

	get(id: number): User | undefined {
		return this.collection.find(element => element.id === id);
	}

	translateApi(json: any) {
		const _this = this;
		const collection: User[] = [];
		json.forEach((p: any) => {
			const user = new User();
			user.id =	Number(p.use_id);
			user.number =	Number(p.use_number);
			user.vorname = p.use_vorname;
			user.nachname = p.use_nachname;
			user.username = p.use_username;
			user.admin = Boolean(p.use_root_privileges);
			user.email = p.use_email;
			user.created = p.use_created_at;
			user.updated = p.use_updated_a;
			collection.push(user);
		});
		_this.collection = collection;
	}

}
