import Price from "../interfaces/Price";
import SourceProduct from "../models/SourceProduct";
import { REQUESTURL } from "../constants/urls";

export default class Group {
	hauser_zusatz: string;
	kategorie_id: number;
	satz_nr: string;
	znrZahl: string;
	jahr: string;
	beschrieb: string;
	langbeschrieb: string;
	prices: Price;
	katalogwert: string;
	products: SourceProduct[];
	gestempelt: boolean;
	postfrisch: boolean;
	gestempeltViererblock: boolean;
	postfrischViererblock: boolean;
	viererBlock: boolean;
	brief: boolean;
	fdc: boolean;
	ersttag: boolean;
	preis: string;

	constructor() {
		this.hauser_zusatz = "";
		this.kategorie_id = 0;
		this.satz_nr = "";
		this.znrZahl = "";
		this.jahr = "";
		this.beschrieb = "";
		this.langbeschrieb = "";
		this.prices = {
			gestempelt: "",
			postfrisch: "",
			gestempeltViererblock: "",
			postfrischViererblock: "",
			brief: "",
			fdc: "",
			ungestempelt: "",
			vollstempelEt: "",
			viererblockEt: "",
		};
		this.products = [];
		this.gestempelt = false;
		this.postfrisch = false;
		this.gestempeltViererblock = false;
		this.postfrischViererblock = false;
		this.viererBlock = false;
		this.brief = false;
		this.fdc = false;
		this.ersttag = false;
		this.preis = "";
		this.katalogwert = "";
	}

	setPrice<K extends keyof Price>(input: K) {
		this.resetZustand();
		switch (input) {
			case "gestempelt":
				this.gestempelt = true;
				break;
			case "postfrisch":
				this.postfrisch = true;
				break;
			case "gestempeltViererblock":
				this.gestempelt = true;
				this.viererBlock = true;
				break;
			case "postfrischViererblock":
				this.postfrisch = true;
				this.viererBlock = true;
				break;
			case "brief":
				this.brief = true;
				break;
			case "fdc":
				this.ersttag = true;
				break;
			default:
				break;
		}
	}

	resetZustand() {
		this.gestempelt = false;
		this.postfrisch = false;
		this.viererBlock = false;
		this.brief = false;
		this.ersttag = false;
	}

	readApi(Store: any) {
		const _this = this;
		const token = localStorage.getItem("token");

		fetch(`${REQUESTURL}/api/group`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${token}`
			},
			body: JSON.stringify(this)
		})
			.then(function(response) {
				if (response.ok) return response.json();
				console.error("Die Rückgabe ist nicht ok");
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.then(function(json) {
				_this.translateApi(json);
				Store.notify();
			})
			.catch(function(err) {
				console.error(`error: ${err}`);
			});
	}

	getLangbeschrieb(): string {
		let output: string =  "Satz, " + this.products.length + " Werte";
		return output;
	}

	calculatePrice<K extends keyof Price>(key: K): string {
		let output = 0
		if(key === 'fdc') {
			output = Number(this.products[0].prices[key])
		} else {
			for (let i in this.products){
				output += Number(this.products[i].prices[key]);
			}
		}
		return String(output);
	}

	defineYears(): string {
		let output: string = '';
		let years: string[] = [];
		for (let i in this.products){
			years.push(this.products[i].jahr)
		}
		years.sort();
		let minYear: string = years[0];
		let maxYear: string = years[years.length - 1];

		if (minYear === maxYear) {
			output = minYear;
		} else {
			output = minYear + '–' + maxYear;
		}
		return output
	}

	defineGroupNr(): string {
		let output: string = '';
		let znrs: string[] = [];
		for (let i in this.products){
			znrs.push(this.products[i].znr)
		}
		znrs.sort();
		let minZNr: string = znrs[0];
		let maxZNr: string = znrs[znrs.length - 1];

		if (minZNr === maxZNr) {
			output = minZNr;
		} else {
			output = minZNr + '–' + maxZNr;
		}
		return output
	}

	defineZNrArray(): string {
		let output: string = '';
		let znrs: string[] = [];
		for (let i in this.products){
			znrs.push(this.products[i].znrZahl)
		}
		znrs.sort();
		for (let i in znrs){
			if (Number(i) === 0) {
				output = znrs[i];
			} else {
				output = output + ', ' + znrs[i];
			}
		}
		return output
	}

	translateApi(json: any) {
		this.hauser_zusatz = json[0].pro_hauser_zusatz;
		this.kategorie_id = Number(json[0].pro_kategorie);
		this.beschrieb = json[0].pro_titel_de;
		let sourceProducts: SourceProduct[] = json.map((sp: any) => {
			let sourceProduct = new SourceProduct();
			sourceProduct.translateApi(sp);
			return sourceProduct;
		});
		this.products = sourceProducts;
		this.langbeschrieb = this.getLangbeschrieb();
		this.prices = {
			gestempelt: this.calculatePrice('gestempelt'),
			postfrisch: this.calculatePrice('postfrisch'),
			gestempeltViererblock: this.calculatePrice('gestempeltViererblock'),
			postfrischViererblock: this.calculatePrice('postfrischViererblock'),
			brief: this.calculatePrice('brief'),
			fdc: this.calculatePrice('fdc'),
			ungestempelt: this.calculatePrice('ungestempelt'),
			vollstempelEt: this.calculatePrice('vollstempelEt'),
			viererblockEt: this.calculatePrice('viererblockEt'),
		}		
		this.jahr = this.defineYears();
		this.satz_nr = this.defineGroupNr();
		this.znrZahl = this.defineZNrArray();
	}
}
