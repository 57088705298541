import React, { Component } from "react";

export default class Footer extends Component {
	render() {
		return (
  <>
    <code className="component_info">{this.constructor.name}</code>
  </>
		);
	}
}
