import { REQUESTURL } from "../constants/urls";
import SourceProduct from "../models/SourceProduct";

export default class SourceProductCollection {
	collection: SourceProduct[];
	count: number;
	localStorageKey: string;

	constructor() {
		this.collection = [];
		this.count = 5;
		this.localStorageKey = "";
	}

	readApi(
		Store: any,
		hauser_zusatz: number | string,
		zumsteinnr: number | string,
		jahr: number | string,
		kategorie: number | string
	) {
		if (Number(hauser_zusatz) === 0) hauser_zusatz = "0";
		if (Number(zumsteinnr) === 0) zumsteinnr = "0";
		if (Number(jahr) === 0) jahr = "0";
		if (kategorie === 0) kategorie = "0";

		const _this = this;
		const url = `${REQUESTURL}quellprodukt/${hauser_zusatz}/${zumsteinnr}/${jahr}/${kategorie}`;

		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		})
			.then(function(response) {
				if (response.ok) return response.json();
				console.error("Die Rückgabe ist nicht ok");
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.then(function(json) {
				_this.translateApi(json);
				_this.getPictures(Store);
			})
			.catch(function(err) {
				console.error(`error: ${err}`);
			});
	}

	getPictures(Store: any) {
		for (let i in this.collection) {
			this.collection[i].getPicture(Store);
		}
		if (this.collection.length === 0) {
			Store.notify();
		}
	}

	countProducts() {}

	updateApi() {
		for (const i in this.collection) {
			const produkt = this.collection[i];
			fetch(REQUESTURL + "update_product", {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(produkt)
			})
				.then(function(response) {
					if (response.ok) return response.json();
					console.error("Die Rückgabe ist nicht ok");
					throw new Error("Irgendwas konnte nicht geladen werden");
				})
				.then(function(json) {})
				.catch(function(err) {
					// Hier Fehlerbehandlung
					console.error(`error: ${err}`);
				});
		}
	}

	deleteApi() {
		//const url = `${REQUESTURL}delete_product/${id}`;
	}

	searchApi(Store: any, suchbegriff: string) {
		const _this = this;
		const url = `${REQUESTURL}suche/${suchbegriff}`;

		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		})
			.then(function(response) {
				if (response.ok) return response.json();
				console.error("Die Rückgabe ist nicht ok");
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.then(function(json) {
				_this.translateApi(json);
				Store.notify();
			})
			.catch(function(err) {
				console.error(`error: ${err}`);
			});
	}

	translateApi(json: any) {
		const back = json.map((p: any) => {
			const sourceProduct = new SourceProduct();
			sourceProduct.translateApi(p);
			return sourceProduct;
		});
		this.collection = back;
	}

	// Saving collection to localStorage
	save() {
		localStorage.setItem(
			this.localStorageKey,
			JSON.stringify(this.collection)
		);
	}
}
