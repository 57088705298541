import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import SourceProduct from "../models/SourceProduct";
import SourceProductPrice from "../components/SourceProductPrice";
import Error from "./Error";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
const $ = Store.data;

interface Props {}
interface State {
	sourceProduct: SourceProduct;
}

export default class SourceProductDetail extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			sourceProduct: new SourceProduct()
		};
		this.handleChangeText = this.handleChangeText.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.takeover = this.takeover.bind(this);
	}

	componentDidMount() {
		Store.attach(this);
		$.group.hauser_zusatz = $.sourceProduct.hauser_zusatz;
		$.group.satz_nr = $.sourceProduct.satznr;
		$.group.readApi(Store);
	}

	update() {
		let sourceProduct = Object.assign({}, $.sourceProduct);
		this.setStateNStoreForce({
			sourceProduct: sourceProduct
		});
	}

	getCategory(id: number): string {
		return $.categoryCollection.getCategoryString(id);
	}

	handleSubmit(event: any) {
		alert(
			"Das speichern eines Quell-Produktes funktioniert derzeit noch nicht."
		);
	}

	takeover() {
		$.product.znr = $.sourceProduct.znr;
		$.product.znrZahl = $.sourceProduct.znrZahl;
		$.product.name_de = $.sourceProduct.title_de;
		$.product.jahr = $.sourceProduct.jahr;
		$.product.kategorie_id = $.sourceProduct.kategorie_id;
		$.product.beschrieb = $.sourceProduct.getLangbeschrieb();
		if ($.sourceProduct.preis !== "") {
			$.product.preis = $.sourceProduct.preis;
		}
		if ($.sourceProduct.katalogwert !== "") {
			$.product.katalogwert = $.sourceProduct.katalogwert;
			$.product.gestempelt = $.sourceProduct.gestempelt;
			$.product.ungebraucht = $.sourceProduct.ungestempelt;
			$.product.postfrisch = $.sourceProduct.postfrisch;
			$.product.viererBlock = $.sourceProduct.viererBlock;
			$.product.brief = $.sourceProduct.brief;
			$.product.ersttag = $.sourceProduct.ersttag;
			$.product.vollstempel = $.sourceProduct.vollstempel;
		}
		Store.notify();
	}

	handleChangeText<K extends keyof SourceProduct>(event: any): void {
		let value: SourceProduct[K] = event.target.value;
		let id: K = event.target.id;
		$.sourceProduct[id] = value;
		Store.notify();
	}

	render() {
		const bild = $.sourceProduct.picturePath;
		let category = $.categoryCollection.getCategoryString(
			$.sourceProduct.kategorie_id
		);
		let beschrieb = $.sourceProduct.getLangbeschrieb();

		return (
			<>
				<div className="source_product">
					<code className="component_info">
						{this.constructor.name}
					</code>
					{false && <Error />}
					<div className="source_bild_div">
						<Image src={bild} className="source_bild" />
					</div>

					<Form.Group controlId="hauser_zusatz">
						<Form.Label>Hauser-Zusatz</Form.Label>
						<Form.Control
							value={this.state.sourceProduct.hauser_zusatz || ""}
							type="text"
							disabled
						/>
					</Form.Group>
					<Form.Group controlId="kategorie">
						<Form.Label>Kategorie</Form.Label>
						<Form.Control
							value={category || ""}
							type="text"
							disabled
						/>
					</Form.Group>
					<Form.Group controlId="znr" className="no-margin-bottom">
						<Form.Label>Zumstein-Nummer</Form.Label>
						<Form.Control
							value={this.state.sourceProduct.znr || ""}
							onChange={this.handleChangeText}
							type="text"
							placeholder="Text eingeben …"
						/>
					</Form.Group>
					<Form.Group controlId="znrZahl">
						<Form.Control
							value={this.state.sourceProduct.znrZahl || ""}
							onChange={this.handleChangeText}
							type="text"
							className="form-control-danger"
							placeholder="Text eingeben …"
						/>
					</Form.Group>
					<Form.Group controlId="title_de">
						<Form.Label>Titel (deutsch)</Form.Label>
						<Form.Control
							value={this.state.sourceProduct.title_de || ""}
							onChange={this.handleChangeText}
							type="text"
							placeholder="Text eingeben …"
						/>
					</Form.Group>
					<Form.Group controlId="jahr">
						<Form.Label>Jahr</Form.Label>
						<Form.Control
							value={this.state.sourceProduct.jahr || ""}
							onChange={this.handleChangeText}
							type="text"
							placeholder="Text eingeben …"
						/>
					</Form.Group>
					<Form.Group controlId="beschrieb">
						<Form.Label>Beschrieb</Form.Label>
						<Form.Control
							value={beschrieb || ""}
							onChange={this.handleChangeText}
							type="text"
							placeholder="Text eingeben …"
						/>
					</Form.Group>
					<SourceProductPrice />
					<Form.Group controlId="preis">
						<Form.Label>Verkaufspreis</Form.Label>
						<Form.Control
							value={this.state.sourceProduct.preis || ""}
							onChange={this.handleChangeText}
							type="text"
							placeholder="0.00"
						/>
					</Form.Group>
					<Button
						variant="primary"
						onClick={this.takeover}
						style={{ marginRight: 5 }}
					>
						Übernehmen
					</Button>

					<Button
						onClick={this.handleSubmit}
						variant="danger"
						style={{ marginRight: 5 }}
					>
						Speichern
					</Button>
				</div>
			</>
		);
	}
}
