import React from "react";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Product from "../models/Product";
import { APIURL } from "../constants/urls";
const $ = Store.data;
const FontAwesome = require("react-fontawesome");

interface Props {}
interface State {
	collection: Product[];
	selectAll: boolean;
	target: any[] | null;
	count: Number;
}

export default class ProductCollectionTable extends Observer<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			collection: [],
			selectAll: false,
			target: null,
			count: 0,
		};
		this.select = this.select.bind(this);
		this.selectAll = this.selectAll.bind(this);
	}

	weiterleiten(id: number) {
		window.location.href = `#/produkt/${id}`;
	}

	getType(type: number) {
		if (type === 1) {
			return <Badge variant="info">Auktion</Badge>;
		} else if (type === 2) {
			return <Badge variant="success">Shop</Badge>;
		} else {
			return <Badge variant="secondary">Undefiniert</Badge>;
		}
	}

	update() {
		this.setStateNStore({
			collection: [...$.productCollection.collection],
			count: $.productCollection.getSelectionCount(),
		});
	}

	private returnClassName(product: Product) {
		let classArray: string[] = []
		if (product.selected) {
			classArray.push("auswahl")
		}
		if (product.sold) {
			classArray.push("sold")
		}
		return classArray.join(" ");
	}

	deleteProduct() {
		let product: Product | undefined =
			$.productCollection.getSelectedProduct();
		if (product !== undefined) {
			$.productCollection.deleteApi(
				Store,
				product.id,
				$.url.anzahl,
				$.url.seite,
				$.search.searchTerm,
				$.search.auctionId,
				$.search.sold,
				$.search.userId,
				$.search.sortColumn,
				$.search.sortOrder,
				$.url.type
			);
		}
	}

	sortColumn(sortcolumn: string) {
		$.search.sortColumn = sortcolumn;
		$.search.sortOrder === "asc"
			? ($.search.sortOrder = "desc")
			: ($.search.sortOrder = "asc");
		$.productCollection.readApi(
			Store,
			$.url.anzahl,
			$.url.seite,
			$.search.searchTerm,
			$.search.auctionId,
			$.search.sold,
			$.search.userId,
			$.search.sortColumn,
			$.search.sortOrder,
			$.url.type
		);
	}

	selectAll() {
		if (this.state.selectAll === true) {
			$.productCollection.unselectAll();
		} else {
			$.productCollection.selectAll();
		}
		Store.notify();
		if (this.state.selectAll === true) {
			this.setState({ selectAll: false });
		} else {
			this.setState({ selectAll: true });
		}
	}

	select(event: any) {
		let id = Number(event.target.id);
		$.productCollection.selectToggle(id);
		Store.notify();
	}

	render() {
		const bild_pfad = `${APIURL}/image/aktiv/`;
		return (
			<React.Fragment>
				<code className="component_info">{this.constructor.name}</code>
				<div className="text-right">
					{this.state.count === 1 && (
						<Button
							variant="outline-primary"
							onClick={this.deleteProduct}
							id="buttonDelete"
						>
							<FontAwesome name="trash" /> Löschen
						</Button>
					)}
					{this.state.count > 1 && (
						<Button
							variant="outline-primary"
							href="#/stappelverarbeitung"
							id="buttonEditMultiple"
						>
							Mehrere Bearbeiten
						</Button>
					)}
				</div>
				<Table bordered responsive hover>
					<thead>
						<tr>
							<th
								onClick={() => this.sortColumn("id")}
								style={{ width: "80px", textAlign: "center" }}
							>
								ID
							</th>
							<th>Bild</th>
							<th style={{ width: "120px" }}>Typ</th>
							<th
								onClick={() => this.sortColumn("znr")}
								style={{ width: "120px" }}
							>
								Z-Nr.
							</th>
							<th
								onClick={() => this.sortColumn("jahr")}
								style={{ width: "120px" }}
							>
								Jahr
							</th>
							<th
								onClick={() => this.sortColumn("name")}
								style={{ minWidth: "250px" }}
							>
								Name
							</th>
							<th
								onClick={() => this.sortColumn("kategorie")}
								style={{ minWidth: "250px" }}
							>
								Kategorie
							</th>
							<th
								className="text-center"
								style={{ width: "50px" }}
							>
								<Form.Check
									type="checkbox"
									checked={this.state.selectAll}
									onChange={this.selectAll}
								/>
							</th>
						</tr>
					</thead>
					<tbody>
						{this.state.collection.map((o: Product, i: number) => (
							<tr
								key={i}
								ref={`reihe${i}`}
								className={this.returnClassName(o)}
							>
								<td
									onClick={() => this.weiterleiten(o.id)}
									style={{ textAlign: "center" }}
								>
									{o.id}
								</td>
								<td
									onClick={() => this.weiterleiten(o.id)}
									className="table-picture"
									style={{ width: "50px" }}
								>
									<Image
										className="vorschau"
										src={`${bild_pfad + o.besitzer}/${
											o.id
										}/100x100/${o.dateiname}`}
									/>
								</td>
								<td onClick={() => this.weiterleiten(o.id)}>
									{this.getType(o.type)}
								</td>
								<td onClick={() => this.weiterleiten(o.id)}>
									{o.znr}
								</td>
								<td onClick={() => this.weiterleiten(o.id)}>
									{o.jahr}
								</td>
								<td onClick={() => this.weiterleiten(o.id)}>
									{o.getShortName()}
								</td>
								<td onClick={() => this.weiterleiten(o.id)}>
									{$.categoryCollection.getCategoryString(
										o.kategorie_id
									)}
								</td>
								<td className="text-center">
									<Form.Check
										type="checkbox"
										ref={String("checkbox" + i) as any}
										id={String(o.id)}
										checked={o.selected}
										onChange={this.select}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</React.Fragment>
		);
	}
}
