import React, {Component} from "react";

class Error extends Component {
	render() {
		return (
			<>
				<code className="component_info">Error.js</code>
				<h1>Error</h1>
				<p>Keine Berechtigung</p>
			</>
		);
	}
}

export default Error;
