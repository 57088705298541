import React, {Component} from "react";

class LogoutContainer extends Component {
	constructor(props: any) {
		super(props);
		localStorage.clear();
		window.location.href = "#/";
		window.location.reload();
	}

	render() {
		return (
			<>
				<h1>Logout …</h1>
			</>
		);
	}
}

export default LogoutContainer;
