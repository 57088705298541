import React from "react";
import Observer from "../observerstore/Observer";
import Store from "../observerstore/Store";
//import Pagina from "../components/Pagina";
import AuctionSearchContainer from "./AuctionSearchContainer";
import AuctionCollectionTable from "../components/AuctionCollectionTable";
import Auction from "../models/Auction";
import Error from "../components/Error";
const $ = Store.data;

interface Props {}
interface State {
	selected: any[] | null;
	target: any[] | null;
	selectAll: boolean;
	collection: Auction[];
}

export default class AuctionCollectionContainer extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			selected: null,
			target: null,
			selectAll: false,
			collection: $.auctionCollection.collection
		};
	}

	update() {
		this.setStateNStore({
			collection: $.auctionCollection.collection
		});
	}

	render() {
		return (
			<div className="container tabelle">
				<code className="component_info">
				{this.constructor.name}
				</code>
				<h1>Alle Auktionen</h1>
				<hr />
				{$.user.root !== 0 && (
					<div>
						<AuctionSearchContainer />
					</div>
				)}
				{false && <Error />}
				{/* <Pagina /> */}
				<AuctionCollectionTable />
			</div>
		);
	}
}
