import React from "react";
import Product from "../models/Product";
import Observer from "../observerstore/Observer";
import Button from "react-bootstrap/Button";
import Store from "../observerstore/Store";
var convert = require("xml-js");
const $ = Store.data;

interface Props {}
interface State {
	productSelection: Product[];
}

export default class XmlDownloadButton extends Observer<Props, State> {
	constructor(props: any) {
		super(props);

		this.state = {
			productSelection: [],
		};

		this.download = this.download.bind(this);
	}

	componentDidMount() {
		Store.attach(this);
		$.categoryCollection.readApi(Store);
	}

	getUserName(id: number) {
		let user = $.userCollection.get(id);
		if(user !== undefined) {
			return user.getFullName();
		} else {
			return 'User wurde nicht gefunden';
		}
	}

	getCategories(id: number) {
		return $.categoryCollection.getXmlString(id);
	}

	getCategory(id: number) {
		return $.categoryCollection.getCategoryString(id);
	}

	update() {
		let productSelection = $.productCollection.getSelectedProducts();
		this.setStateNStore({
			productSelection: productSelection,
		});
	}

	download() {
		let productArray: any[] = [];
		for (let i in this.state.productSelection) {
			productArray.push(this.product2json(this.state.productSelection[i]));
		}

		let xmlJson = {
			_declaration: {
				_attributes: { version: "1.0", encoding: "utf-8" }
			},
			PRODUCTS: {
				PRODUCT: productArray
			}
		};

		let options = {
			compact: true,
			spaces: 4,
			alwaysChildren: false
		};

		var result = convert.json2xml(JSON.stringify(xmlJson), options);

		let element = document.createElement("a");
		let file = new Blob([result], { type: "text/xml" });
		element.href = URL.createObjectURL(file);
		element.download = "opencart_export.xml";
		document.body.appendChild(element); // Required for this to work in Firefox
		element.click();
	}

	product2json(product: Product) {
		// TODO: Attribute müssen noch implementiert werden
		return {
			MODEL: { _cdata: product.getModelNumber() },
			SKU: { _cdata: "" },
			UPC: { _cdata: "" },
			EAN: { _cdata: "" },
			JAN: { _cdata: "" },
			ISBN: { _cdata: "" },
			MPN: { _cdata: "" },
			Location: { _cdata: "" },
			ProductName: { _cdata: String(product.name_de) },
			MetaTagDescription: { _cdata: "" },
			MetaTagKeywords: { _cdata: "" },
			Description: { _cdata: String(product.getDescription()) },
			ProductTags: { _cdata: "" },
			Price: String(product.preis),
			Quantity: "1",
			MinimumQuantity: "1",
			SubtractStock: "1",
			OutOfStockStatus: "7",
			RequiresShipping: "1",
			SEOKeyword: { _cdata: "" },
			Image: String(product.getFirstImageString()),
			DateAvailable: "2020-07-03",
			LengthClass: "1",
			Length: "0.00000000",
			Width: "0.00000000",
			height: "0.00000000",
			Weight: "0.00000000",
			WeightClass: "1",
			Status: "1",
			SortOrder: "1",
			Manufacturer: { _cdata: String(this.getUserName(product.besitzer)) },
			Categories: String(this.getCategories(product.kategorie_id)),
			Stores: "default;",
			RelatedProduct: "",
			OptionName: "",
			OptionValue: "",
			additionalImages: String(product.getXmlImages()),
			SpeicalPrice: "",
			TaxClass: "0",
			FilterGroupName: "",
			FilterNames: "",
			Attributes: { _cdata: String(product.getXmlAttributes(this.getCategory(product.kategorie_id)))},
			Discount: "",
			RewardPoints: "0",
			MetaTitle: { _cdata: String(product.name_de) },
			Viewed: "0",
			Downloadid: "",
			Reviews: ""
		};
	}

	render() {
		return <Button onClick={this.download}>Download XML</Button>;
	}
}
