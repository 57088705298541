export default class Category {
	id: number;
	level_1: string;
	level_2: string;
	level_3: string;
	level_4: string;
	created: string;
	updated: string;

	constructor() {
		this.id = 0;
		this.level_1 = "";
		this.level_2 = "";
		this.level_3 = "";
		this.level_4 = "";
		this.created = "";
		this.updated = "";
	}

	getCategory(): string {
		let back = "";
		if (this.level_1 !== "") back += this.level_1;
		if (this.level_2 !== "") back = `${back} • ${this.level_2}`;
		if (this.level_3 !== "") back = `${back} • ${this.level_3}`;
		if (this.level_4 !== "") back = `${back} • ${this.level_4}`;
		return back;
	}

	getFullCategory(): string {
		let back = "";
		if (this.level_1 !== "") back += this.level_1;
		if (this.level_2 !== "") back = `${back} • ${this.level_2}`;
		if (this.level_3 !== "") back = `${back} • ${this.level_3}`;
		if (this.level_4 !== "") back = `${back} • ${this.level_4}`;
		return back;
	}

	getXMLCategory(): string {
		let back = "";
		if (this.level_1 !== "") back += this.level_1;
		if (this.level_2 !== "") back += `;${this.level_1} > ${this.level_2}`;
		if (this.level_3 !== "") back += `;${this.level_1} > ${this.level_2} > ${this.level_3}`;
		if (this.level_4 !== "") back += `;${this.level_1} > ${this.level_2} > ${this.level_3} > ${this.level_4}`;
		return back;
	}
}
