import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import AuctionCreaterContainer from "../containers/AuctionCreaterContainer";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
const $ = Store.data;

interface Props {}
interface State {
	show: boolean;
}

export default class AuctionCreaterView extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			show: false
		};
		this.alarm = this.alarm.bind(this);
	}

	alarm() {
		this.setState({ show: true });
		setTimeout(() => {
			this.setState({ show: false });
		}, 2500);
	}

	componentDidMount() {
		Store.attach(this);
		$.user.checkUser(Store);
	}

	render() {
		return (
			<>
				<code className="component_info">AuctionCreaterView.js</code>
				<Alert variant="success" show={this.state.show}>
					Update war erfolgreich
				</Alert>
				<Container>
					<Row>
						<Col sm={3} />
						<Col sm={6} className="white">
							<AuctionCreaterContainer />
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}
