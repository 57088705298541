import React from "react";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
const $ = Store.data;

interface Props {}
interface State {
	value: string;
}

export default class AuctionSearchContainer extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			value: ""
		};

		this.search = this.search.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event: any) {
		this.setState({ value: event.target.value });
	}

	search() {
		let value: string = this.state.value;
		if (value === "") {
			value = "0";
		}
		$.search.searchTerm = value;
		$.auctionCollection.readApi(Store, $.url.anzahl, $.url.seite, value);
	}

	render() {
		return (
			<>
				<code className="component_info">{this.constructor.name}</code>
				<InputGroup className="mb-3">
					<InputGroup.Append>
						<Button variant="primary" onClick={this.search}>
							Suchen
						</Button>
					</InputGroup.Append>
					<FormControl
						value={this.state.value}
						onChange={this.handleChange}
						onKeyPress={this.search}
						type="text"
						placeholder="Suche"
					/>
				</InputGroup>
			</>
		);
	}
}
