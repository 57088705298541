import React from "react";
import Form from "react-bootstrap/Form";
import Auction from "../models/Auction";
import Observer from "../observerstore/Observer";
import Store from "../observerstore/Store";
const $ = Store.data;

interface Props {
	number: number;
}
interface State {
	auction: Auction;
}


export default class HighlightSelect extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			auction: new Auction(),
		};
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		Store.attach(this);
	}

	update() {
		this.setStateNStoreForce({
			auction: $.auction,
		});
	}

	handleChange(event: any) {
		const value: number = Number(event.target.value);
		$.auction.setHighlight(value, this.props.number);
		console.log($.auction.getHighlights());
		Store.notify();
	}

	render() {
		return (
			<>
				<code className="component_info">{this.constructor.name}</code>
				<Form.Group controlId="auctionSelect">
					<Form.Label>Produkt-Highlight {this.props.number}</Form.Label>
					<Form.Control
						as="select"
						onChange={this.handleChange}
						value={String(this.state.auction.getHighlight(this.props.number).id)}
					>
						<option value={0}>Keine Auktion</option>
						{this.state.auction.getNonHighlightsArray(this.props.number).map((o, i) => {
							return (
								<option key={i} value={o.id}>
									{o.toString()}
								</option>
							);
						})}
					</Form.Control>
				</Form.Group>
			</>
		);
	}
}
