import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
const $ = Store.data;

interface Props {}
interface State {
	username: string;
	password: string;
}

export default class LoginView extends Observer<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			username: "",
			password: ""
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInput = this.handleInput.bind(this);
	}

	update() {
		if ($.user.id !== 0) {
			window.location.href = "#/produktuebersicht/0/10/1";
		}
	}

	componentDidMount() {
		Store.attach(this);
		$.user.checkUser(Store);
	}

	async handleSubmit() {
		let status = await $.user.loginUser(this.state.username, this.state.password);
		if(status) {
			window.location.href = "#/produktuebersicht/0/10/1";
		} else {
			this.activateMessage();
		}
		Store.notify();
	}

	activateMessage() {
		$.alertBad = true;
		Store.notify();
	}

	handleInput(event: any) {
		const value: string = event.target.value;
		const key: string = event.target.id;

		if (key === "username") this.setState({ username: value });
		if (key === "password") this.setState({ password: value });
	}

	render() {
		return (
			<>
				<code className="component_info">LoginView.js</code>
				<div className="loginContainer">
					<div className="loginForm" id="loginForm">
						<Image
							src={"./img/Logo_RJ.png"}
							className="loginBild"
							id="loginBild"
							fluid
						/>
						<hr />

						<Form.Group controlId="username">
							<Form.Control
								onChange={this.handleInput}
								type="text"
								placeholder="Benutzername"
								value={this.state.username}
							/>
						</Form.Group>

						<Form.Group controlId="password">
							<Form.Control
								onChange={this.handleInput}
								type="password"
								placeholder="Passwort"
								value={this.state.password}
							/>
						</Form.Group>
						<Button
							variant="primary"
							type="submit"
							id="loginButton"
							onClick={this.handleSubmit}
							block
						>
							Login
						</Button>
					</div>
				</div>
			</>
		);
	}
}
