import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Auction from "../models/Auction";
import Error from "../components/Error";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
const $ = Store.data;

interface Props {}
interface State {
	auction: Auction;
}

export default class AuctionCreaterContainer extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			auction: new Auction()
		};
		this.handleChangeText = this.handleChangeText.bind(this);
		this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit() {
		$.auction.createApi();
		this.activateMessage();
	}

	activateMessage() {
		$.alertGood = true;
	}

	update() {
		let auction: Auction = $.auction;
		this.setStateNStoreForce({
			auction: auction
		});
	}

	handleChangeText<K extends keyof Auction>(event: any) {
		const key: K = event.target.id;
		const value: Auction[K] = event.target.value;
		$.auction[key] = value;
		Store.notify();
	}

	handleChangeCheckbox<K extends keyof Auction>(event: any) {
		const target = event.target;
		const value: Auction[K] =
			target.type === "checkbox" ? target.checked : target.value;
		const key: K = target.id;
		$.auction[key] = value;
		Store.notify();
	}

	render() {
		return (
			<>
				<div className="container tabelle">
					<code className="component_info">
						AuctionCreaterContainer.js
					</code>
					<h1>Neue Auktion erstellen</h1>
					<hr />
					{false && <Error />}

						<Form.Group controlId="aktiv">
							<Form.Check
								checked={this.state.auction.aktiv || false}
								onChange={this.handleChangeCheckbox}
								type="checkbox"
								label="Aktiv"
							/>
						</Form.Group>
						<Form.Group controlId="name">
							<Form.Label>Name</Form.Label>
							<Form.Control
								value={this.state.auction.name || ""}
								onChange={this.handleChangeText}
								type="text"
								placeholder="Text eingeben …"
							/>
						</Form.Group>
						<Form.Group controlId="start">
							<Form.Label>Start</Form.Label>
							<Form.Control
								value={this.state.auction.start || ""}
								onChange={this.handleChangeText}
								type="text"
								placeholder="2019-07-04 16:00:00"
							/>
						</Form.Group>
						<Form.Group controlId="ende">
							<Form.Label>Ende</Form.Label>
							<Form.Control
								value={this.state.auction.ende || ""}
								onChange={this.handleChangeText}
								type="text"
								placeholder="2019-07-04 16:00:00"
							/>
						</Form.Group>
						<Form.Group controlId="beschrieb">
							<Form.Label>Beschrieb</Form.Label>
							<Form.Control
								value={this.state.auction.beschrieb || ""}
								onChange={this.handleChangeText}
								as="textarea"
								rows={3}
								placeholder="Text eingeben …"
							/>
						</Form.Group>

						<Button variant="primary" type="submit" onClick={this.handleSubmit}>
							Speichern
						</Button>
				</div>
			</>
		);
	}
}
