import Auction from "../models/Auction";
import {REQUESTURL} from "../constants/urls";

export default class AuctionCollection {

	collection: Auction[];
	localStorageKey: string;

	constructor() {
		this.collection = [];
		this.localStorageKey = "";
	}

	getAuctionById(id: number): Auction | undefined {
		return this.collection.find(auction => auction.id === id);
	}

	get(id: number): Auction | undefined {
		return this.collection.find(element => element.id === id);
	}

	getSelectionCount(): number {
		return this.collection.filter(auction => auction.selected === true).length;
	}

	getSelectedAuctions() {
		return this.collection.filter(auction => auction.selected === true );
	}

	unselectAll() {
		for (let i in this.collection) {
			this.collection[i].selected = false;
		}
	}

	selectAll() {
		for (let i in this.collection) {
			this.collection[i].selected = true;
		}
	}

	selectToggle(id: number) {
		let auction: Auction | undefined = this.getAuctionById(id);
		if (auction !== undefined) {
			auction.selected = !auction.selected;
		}
	}

	readApi(Store: any, anzahl: number, seite: number, searchterm: string) {
		const _this = this;
		const url = REQUESTURL + `alle_auktionen/${anzahl}/${seite}/${searchterm}`;

		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		})
			.then(function (response) {
				if (response.ok) return response.json();
				console.log("Die Rückgabe ist nicht ok");
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.then(function (json) {
				_this.translateApi(json);
				Store.notify()
			})
			.catch(function (err) {
				console.log(`error: ${err}`);
			});
	}

	searchApi(suchbegriff: string) {
		const _this = this;
		const url = REQUESTURL + `auktion_suche/${suchbegriff}`;

		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		})
			.then(function (response) {
				if (response.ok) return response.json();
				console.log("Die Rückgabe ist nicht ok");
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.then(function (json) {
				_this.translateApi(json);
			})
			.catch(function (err) {
				console.log(`error: ${err}`);
			});
	}

	deleteApi(Store: any, id: number, anzahl: number, seite: number) {
		const _this = this;
		const url = `${REQUESTURL}delete_auction/${id}`;

		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		})
			.then(function (response) {
				if (response.ok) return response.json();
				console.log("Die Rückgabe ist nicht ok");
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.then(function () {
				_this.readApi(Store, anzahl, seite, '0');
			})
			.catch(function (err) {
				console.log(`error: ${err}`);
			});
	}

	filterActive() {
		console.log("Diese Funktion kann noch implementiert werden");
	}

	translateApi(json: any) {
		const back = json.map((p: any, i: number) => {
			return new Auction(
				Number(p.auk_id),
				p.auk_name,
				p.auk_start,
				p.auk_ende,
				p.auk_beschrieb,
				p.auk_aktiv,
				p.created_at,
				p.updated_at
			);
		});
		this.collection = back;
	}

	updateLocalStorage() {
		localStorage.setItem(
			this.localStorageKey,
			JSON.stringify(this.collection)
		);
	}
}
