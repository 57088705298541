import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import HighlightSelect from '../components/HighlightSelect';
import Auction from "../models/Auction";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
const $ = Store.data;

interface Props {
	id: number;
}
interface State {
	auction: Auction;
}

export default class AuctionContainer extends Observer<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			auction: new Auction()
		};
		this.handleChangeText = this.handleChangeText.bind(this);
		this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	update() {
		this.setStateNStoreForce({
			auction: $.auction,
		});
	}

	componentDidMount() {
		Store.attach(this);
		$.auction.readApi(Store, this.props.id)
	}

	handleSubmit(): void {
		$.auction.updateApi();
		this.activateMessage();
	}

	handleChangeText<K extends keyof Auction>(event: any) {
		const value: Auction[K] = event.target.value;
		const key: K = event.target.id;
		let auction: Auction = this.state.auction;
		auction[key] = value;
		this.setStateNStoreForce({
			auction: auction
		});
	}

	handleChangeCheckbox<K extends keyof Auction>(event: any) {
		const target = event.target;
		const value: Auction[K] =
			target.type === "checkbox" ? target.checked : target.value;
		const key: K = target.id;
		let auction: Auction = this.state.auction;
		auction[key] = value;
		this.setStateNStoreForce({
			auction: auction
		});
	}

	activateMessage() {
		$.alertGood = true;
		Store.notify();
	}


	render() {
		return (
			<>
				<code className="component_info">{this.constructor.name}</code>

				<Badge variant="secondary">
					Auktion #{this.props.id}
				</Badge>
				<h1>{this.state.auction.name}</h1>
				<hr />
					<Form.Group controlId="aktiv">
						<Form.Check
							checked={this.state.auction.aktiv || false}
							onChange={this.handleChangeCheckbox}
							type="checkbox"
							label="Aktiviert"
						/>
					</Form.Group>
					<Form.Group controlId="name">
						<Form.Label>Name</Form.Label>
						<Form.Control
							value={this.state.auction.name || ""}
							onChange={this.handleChangeText}
							type="text"
							placeholder="Text eingeben …"
						/>
					</Form.Group>
					<Form.Group controlId="start">
						<Form.Label>Start</Form.Label>
						<Form.Control
							value={this.state.auction.start || ""}
							onChange={this.handleChangeText}
							type="text"
							placeholder="Text eingeben …"
						/>
					</Form.Group>
					<Form.Group controlId="ende">
						<Form.Label>Ende</Form.Label>
						<Form.Control
							value={this.state.auction.ende || ""}
							onChange={this.handleChangeText}
							type="text"
							placeholder="Text eingeben …"
						/>
					</Form.Group>
					<Form.Group controlId="beschrieb">
						<Form.Label>Beschrieb</Form.Label>
						<Form.Control
							value={this.state.auction.beschrieb || ""}
							onChange={this.handleChangeText}
							as="textarea"
							rows={3}
							placeholder="Text eingeben …"
						/>
					</Form.Group>

					<HighlightSelect number={1} />
					<HighlightSelect number={2} />
					<HighlightSelect number={3} />

					<Button variant="primary" type="submit" onClick={this.handleSubmit}>
						Speichern
					</Button>
			</>
		);
	}
}
