import { APIURL } from "../constants/urls";
import Price from "../interfaces/Price";
import { REQUESTURL } from "../constants/urls";

export default class SourceProduct {
	id: number;
	hauser_zusatz: string;
	kategorie_id: number;
	znr: string;
	znrZahl: string;
	satznr: string;
	title_de: string;
	name_de: string;
	jahr: string;
	beschrieb: string;
	langbeschrieb: string;
	wertstufe: string;
	wertstufe_waehrung: string;
	bild: string;
	localStorageKey: string;
	prices: Price;
	gestempelt: boolean;
	vollstempel: boolean;
	ungestempelt: boolean;
	postfrisch: boolean;
	gestempeltViererblock: boolean;
	postfrischViererblock: boolean;
	viererBlock: boolean;
	brief: boolean;
	fdc: boolean;
	ersttag: boolean;
	preis: string;
	katalogwert: string;
	picturePath: string;

	constructor() {
		this.id = 0;
		this.hauser_zusatz = "";
		this.kategorie_id = 0;
		this.znr = "";
		this.znrZahl = "";
		this.satznr = "";
		this.title_de = "";
		this.name_de = "";
		this.jahr = "";
		this.beschrieb = "";
		this.langbeschrieb = "";
		this.wertstufe = "";
		this.wertstufe_waehrung = "";
		this.bild = "";
		this.localStorageKey = "SourceProduct";
		this.prices = {
			gestempelt: "",
			postfrisch: "",
			gestempeltViererblock: "",
			postfrischViererblock: "",
			brief: "",
			fdc: "",
			ungestempelt: "",
			vollstempelEt: "",
			viererblockEt: "",
		};
		this.ungestempelt = false;
		this.gestempelt = false;
		this.vollstempel = false;
		this.postfrisch = false;
		this.gestempeltViererblock = false;
		this.postfrischViererblock = false;
		this.viererBlock = false;
		this.brief = false;
		this.fdc = false;
		this.ersttag = false;
		this.preis = "";
		this.katalogwert = "";
		this.picturePath = `${APIURL}/image/referenzdatenbank/default.jpg`;
	}

	cleanZnr(input: string): string {
		let output: string = input;
		output = output.replaceAll(" ", "_");
		output = output.replaceAll("(", "-");
		output = output.replaceAll(")", "-");
		return output;
	}

	getPicturePath(): string {

		let znr = this.cleanZnr(this.znr);
		const directory = `CHE_${this.hauser_zusatz}_${znr}`;
		const bildpfad = `${APIURL}/image/referenzdatenbank/CHE/${directory}/${directory}_Xx600.jpg`;
		return bildpfad;
	}

	setPrice<K extends keyof Price>(input: K) {
		this.resetZustand();
		switch (input) {
			case "gestempelt":
				this.gestempelt = true;
				break;
			case "ungestempelt":
				this.ungestempelt = true;
				break;
			case "postfrisch":
				this.postfrisch = true;
				break;
			case "gestempeltViererblock":
				this.gestempelt = true;
				this.viererBlock = true;
				break;
			case "postfrischViererblock":
				this.postfrisch = true;
				this.viererBlock = true;
				break;
			case "brief":
				this.brief = true;
				break;
			case "fdc":
				this.ersttag = true;
				break;
			case "vollstempelEt":
				this.vollstempel = true;
				this.ersttag = true;
				break;
			case "viererblockEt":
				this.viererBlock = true;
				this.ersttag = true;
				break;
			default:
				break;
		}
	}

	resetZustand() {
		this.gestempelt = false;
		this.ungestempelt = false;
		this.postfrisch = false;
		this.viererBlock = false;
		this.brief = false;
		this.ersttag = false;
		this.vollstempel = false;

	}

	readApi(Store: any, id: number) {
		const _this = this;
		const token = localStorage.getItem("token");

		fetch(`${REQUESTURL}api/readProductSource`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${token}`
			},
			body: JSON.stringify({ id: id })
		})
			.then(function(response) {
				if (response.ok) return response.json();
				console.error("Die Rückgabe ist nicht ok");
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.then(function(json) {
				_this.translateApi(json);
				_this.getPicture(Store);
			})
			.catch(function(err) {
				console.error(`error: ${err}`);
			});
	}

	getPicture(Store: any): void {
		const _this = this;

		fetch(this.getPicturePath(), {
			method: "GET"
		}).then(function(response) {
			if (response.status === 404) {
				_this.picturePath = `${APIURL}/image/referenzdatenbank/default.jpg`;
			}
			Store.notify();
		});
	}

	getPictures(Store: any) {
		const _this = this;

		fetch(`${REQUESTURL}bilder/${this.id}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		})
			.then(function(response) {
				if (response.ok) return response.json();
				console.error("Die Rückgabe ist nicht ok");
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.then(function(json: any) {
				const bilder: string[] = [];
				json.map((p: any, i: number) => {
					bilder.push(p.linver_dateiname);
					return 0;
				});
				_this.bild = bilder[0];
				Store.notify();
			})
			.catch(function(err) {
				console.error(`error: ${err}`);
			});
	}

	updateApi() {
		const _this = this;
		fetch(REQUESTURL + "update_product", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(_this)
		})
			.then(function(response) {
				if (response.ok) return response.json();
				console.error("Die Rückgabe ist nicht ok");
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.catch(function(err) {
				// Hier Fehlerbehandlung
				console.error(`error: ${err}`);
			});
	}

	translateApi(json: any) {
		this.id = Number(json.pro_id);
		this.hauser_zusatz = json.pro_hauser_zusatz;
		this.kategorie_id = Number(json.pro_kategorie);
		this.znr = json.pro_znr;
		this.znrZahl = json.pro_znr_zahl;
		this.satznr = json.pro_satznr;
		this.title_de = json.pro_titel_de;
		this.name_de = json.pro_name_de;
		this.jahr = json.pro_jahr;
		this.beschrieb = json.pro_beschrieb_de;
		this.langbeschrieb = json.pro_langbeschrieb_de;
		this.wertstufe = json.pro_wertstufe;
		this.wertstufe_waehrung = json.pro_wertstufe_waehrung;
		this.bild = json.lin_dateiname;
		this.prices.gestempelt = this.checkPrice(json.pro_gestempelt);
		this.prices.ungestempelt = this.checkPrice(json.pro_ungestempelt);
		this.prices.postfrisch = this.checkPrice(json.pro_postfrisch);
		this.prices.gestempeltViererblock = this.checkPrice(json.pro_block);
		this.prices.postfrischViererblock = this.checkPrice(json.pro_block_ungestempelt);
		this.prices.brief = this.checkPrice(json.pro_brief);
		this.prices.fdc = this.checkPrice(json.pro_fdc);
		this.prices.ungestempelt = this.checkPrice(json.pro_ungestempelt);
		this.prices.vollstempelEt = this.checkPrice(json.pro_vollstempel);
		this.prices.viererblockEt = this.checkPrice(json.pro_et_block_de);
		this.picturePath = this.getPicturePath();
	}

	private checkPrice(input: any): any {
		if(input === null) {
			return 'unbekannt'
		}
		return input
	}

	true_or_false(input: any) {
		if (Number(input) === 0) {
			return false;
		}
		return true;
	}

	getKurzbeschrieb(): string {
		let prefix = "";
		let suffix = "";
		if (this.wertstufe !== "" && this.wertstufe_waehrung !== "") {
			prefix = this.wertstufe + " " + this.wertstufe_waehrung;
		}
		if (this.beschrieb !== "" && prefix !== "") {
			suffix = ", ";
		}
		if (this.beschrieb !== "") {
			suffix = suffix + this.beschrieb;
		}
		return prefix + suffix;
	}

	getLangbeschrieb(): string {
		let prefix = "";
		let suffix = "";
		if (this.wertstufe !== "" && this.wertstufe_waehrung !== "") {
			prefix = this.wertstufe + " " + this.wertstufe_waehrung;
		}
		if (this.beschrieb !== "" && prefix !== "") {
			suffix = ", ";
		}
		if (this.beschrieb !== "") {
			suffix = suffix + this.beschrieb;
		}
		if (this.langbeschrieb !== "") {
			suffix = suffix + '. ' + this.langbeschrieb;
		}
		return prefix + suffix;
	}

	createLocalStorage() {
		localStorage.setItem(this.localStorageKey, JSON.stringify(this));
	}
}
