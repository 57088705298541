import { REQUESTURL } from "../constants/urls";
import Product from "../models/Product";

class ProductCollection {
	collection: Product[];
	count: number;
	localStorageKey: string;

	constructor() {
		this.collection = [];
		this.count = 5;
		this.localStorageKey = "token";
	}

	getSelectionCount(): number {
		return this.collection.filter(p => p.selected === true).length;
	}

	getSelectedProducts(): Product[] {
		return this.collection.filter(product => product.selected === true);
	}

	getSelectedProduct(): Product | undefined {
		return this.collection.find(product => product.selected === true);
	}

	unselectAll() {
		for (let i in this.collection) {
			this.collection[i].selected = false;
		}
	}

	selectAll() {
		for (let i in this.collection) {
			this.collection[i].selected = true;
		}
	}

	selectToggle(id: number) {
		let product: Product | undefined = this.getProductById(id);
		if (product !== undefined) {
			product.selected = !product.selected;
		}
	}

	getProductById(id: number): Product | undefined {
		return this.collection.find(p => p.id === id);
	}

	readApi(
		Store: any,
		anzahl: number,
		seite: number,
		suchbegriff: string,
		auk_id: number,
		sold: number,
		user_id: number,
		sortcolumn: string,
		sortorder: string,
		type: number
	) {
		const _this = this;
		const url = `${REQUESTURL}api/produkte_neu/${anzahl}/${seite}/${suchbegriff}/${auk_id}/${sold}/${user_id}/${sortcolumn}/${sortorder}/${type}`;
		const token = localStorage.getItem(this.localStorageKey);

		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${token}`
			}
		})
			.then(function(response) {
				if (response.ok) return response.json();
				console.error("Die Rückgabe ist nicht ok");
				window.location.href = "#/";
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.then(function(json) {
				_this.translateAPI(Store, json);
				Store.notify();
			})
			.catch(function(err) {
				console.error(`error: ${err}`);
			});
	}

	deleteApi(
		Store: any,
		id: number,
		anzahl: number,
		seite: number,
		suchbegriff: string,
		auk_id: number,
		sold: number,
		user_id: number,
		sortcolumn: string,
		sortorder: string,
		type: number
	) {
		const _this = this;
		const url = `${REQUESTURL}delete_product/${id}`;

		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		})
			.then(function(response) {
				if (response.ok) return response.json();
				console.error("Die Rückgabe ist nicht ok");
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.then(function(json: any) {
				_this.readApi(
					Store,
					anzahl,
					seite,
					suchbegriff,
					auk_id,
					sold,
					user_id,
					sortcolumn,
					sortorder,
					type
				);
			})
			.catch(function(err) {
				console.error(`error: ${err}`);
			});
	}

	true_or_false(input: any) {
		if (Number(input) === 0 || input === null) {
			return false;
		}
		return true;
	}

	stringCheck(input: any): string {
		let output: string = String(input);
		return (output !== 'null') ? output : '';
	}

	translateAPI(Store: any, json: any) {
		this.collection = json.products.map((p: any) => {
			let product: Product = new Product();
			product.translateApi(p);
			return product;
		});
		this.count = Number(json.count)
		Store.notify();
	}

	searchId(id: number) {
		return this.collection.find(p => p.id === id);
	}

	all() {
		return this.collection;
	}
}

export default ProductCollection;
