import ObserverType from "./ObserverType";
import Category from "../models/Category";
import CategoryCollection from "../models/CategoryCollection";
import User from "../models/User";
import Product from "../models/Product";
import Group from "../models/Group";
import SourceProduct from "../models/SourceProduct";
import Auction from "../models/Auction";
import ProductCollection from "../models/ProductCollection";
import SourceProductCollection from "../models/SourceProductCollection";
import AuctionCollection from "../models/AuctionCollection";
import UserCollection from "../models/UserCollection";
import Data from "../interfaces/Data";

class Store {
	observers: ObserverType[];
	data: Data;

	constructor() {
		this.observers = [];
		this.data = {
			auction: new Auction(),
			auctionCollection: new AuctionCollection(),
			product: new Product(),
			productCollection: new ProductCollection(),
			category: new Category(),
			categoryCollection: new CategoryCollection(),
			sourceProduct: new SourceProduct(),
			sourceProductCollection: new SourceProductCollection(),
			group: new Group(),
			user: new User(),
			userCollection: new UserCollection(),
			alertGood: false,
			alertBad: false,
			url: {
				anzahl: 15,
				seite: 1,
				type: 0,
				user: 0
			},
			search: {
				searchTerm: "0",
				auctionId: 0,
				sold: 2,
				userId: 0,
				sortColumn: "id",
				sortOrder: "asc"
			},
			sourceSearch: {
				hauser_zusatz: "",
				znr: "",
				jahr: "",
				kategorie_id: 0
			}
		};
	}

	attach(observer: ObserverType): void {
		this.observers.push(observer);
	}

	detach(observer: ObserverType): void {
		for (let i = 0; i < this.observers.length; i++) {
			if (this.observers[i] === observer) {
				this.observers.splice(i, 1);
			}
		}
	}

	print(): void {
		console.log(this.data);
	}

	notify(): void {
		let i;
		for (i = 0; i < this.observers.length; i++) {
			this.observers[i].update();
		}
	}
}

export default new Store();
