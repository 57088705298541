import { REQUESTURL } from "../constants/urls";
import { ATTRIBUTES } from "../constants/attributes";
import Category from "../models/Category";

class Product {
	id: number;
	number: number;
	type: number;
	auktions_id: number;
	los_nr: number;
	znr: string;
	znrZahl: string;
	name_de: string;
	jahr: string;
	beschrieb: string;
	langbeschrieb: string;
	dateiname: string;
	katalogwert: string;
	preis: string;
	kategorie_id: number;
	category: Category;
	auktions_ende: string;
	highlight: number;
	besitzer: number;
	gestempelt: boolean;
	vollstempel: boolean;
	brief: boolean;
	briefausschnitt: boolean;
	ersttag: boolean;
	postfrisch: boolean;
	ungebraucht: boolean;
	ungebraucht_oG: boolean;
	viererBlock: boolean;
	einheit: boolean;
	sold: boolean;
	luxus: boolean;
	raritaet: boolean;
	bilder: string[];
	localStorageKey: string;
	selected: boolean;

	constructor() {
		this.id = 0;
		this.number = 0;
		this.type = 0;
		this.auktions_id = 0;
		this.los_nr = 0;
		this.znr = "";
		this.znrZahl = "";
		this.name_de = "";
		this.jahr = "";
		this.beschrieb = "";
		this.langbeschrieb = "";
		this.dateiname = "";
		this.katalogwert = "";
		this.preis = "";
		this.kategorie_id = 0;
		this.category = new Category();
		this.auktions_ende = "";
		this.highlight = 0;
		this.gestempelt = false;
		this.vollstempel = false;
		this.brief = false;
		this.briefausschnitt = false;
		this.ersttag = false;
		this.postfrisch = false;
		this.ungebraucht = false;
		this.ungebraucht_oG = false;
		this.viererBlock = false;
		this.einheit = false;
		this.sold = false;
		this.luxus = false;
		this.raritaet = false;
		this.bilder = [];
		this.besitzer = 0;
		this.localStorageKey = "token";
		this.selected = false;
	}

	toString() {
		return this.id + " – " + this.name_de;
	}

	getModelNumber(): string {
		let output: string = "";

		let besitzer: string = this.equalizeNumber(this.besitzer, 3);
		let id: string = this.equalizeNumber(this.id, 5);
		id = this.isolateNumber(id, ".");

		output = id + "-" + besitzer;
		return output;
	}

	equalizeNumber(numb: number, places: number): string {
		let prefix: string = "";
		let i: number = String(numb).length;
		while (i < places) {
			prefix += "0";
			i++;
		}

		return prefix + numb;
	}

	isolateNumber(numb: string, sign: string): string {
		let output: string = "";
		let array: string[] = Array.from(String(numb));
		array.reverse();
		for (let x in array) {
			if (Number(x) % 3 === 0 && Number(x) !== 0) {
				output = sign + output;
			}
			output = array[x] + output;
		}
		return output;
	}

	hasAttributes(): boolean {
		return (
			this.gestempelt ||
			this.vollstempel ||
			this.brief ||
			this.briefausschnitt ||
			this.ersttag ||
			this.postfrisch ||
			this.ungebraucht ||
			this.ungebraucht_oG ||
			this.viererBlock ||
			this.einheit ||
			this.luxus ||
			this.raritaet
		);
	}

	resetZustand() {
		this.gestempelt = false;
		this.postfrisch = false;
		this.viererBlock = false;
		this.brief = false;
		this.ersttag = false;
	}

	getDescription(): string {
		let output = "";
		if (this.langbeschrieb !== "") {
			let langbeschrieb = this.langbeschrieb;
			langbeschrieb = langbeschrieb.replace(/(?:\r\n|\r|\n)/g, "<br />");
			output = output + langbeschrieb + "<br />";
		}
		output =
			output + "Zustand und Erhaltung gemäss Bilder und/oder Attest.";
		return output;
	}

	getFirstImageString(): string {
		return (
			REQUESTURL +
			"image/aktiv/" +
			this.besitzer +
			"/" +
			this.id +
			"/original/" +
			this.dateiname
		);
	}

	getXmlAttributes<K extends keyof this>(categoryString: string): string {
		let output: string = "";
		for (let i in ATTRIBUTES) {
			let attribut: K = ATTRIBUTES[i].name as K;
			if (String(this[attribut]) !== "false") {
				if (String(this[attribut]) === "true") {
					output =
						output +
						ATTRIBUTES[i].prefix +
						"true" +
						ATTRIBUTES[i].suffix +
						";";
				} else {
					let temp: string = String(this[attribut]);
					temp = this.xmlCleanString(temp);
					if (ATTRIBUTES[i].name === "langbeschrieb") {
						if (temp !== "") {
							temp =
								temp +
								"<br />Zustand und Erhaltung gemäss Bilder und/oder Attest.";
						} else {
							temp =
								temp +
								"Zustand und Erhaltung gemäss Bilder und/oder Attest.";
						}
					}
					output =
						output +
						ATTRIBUTES[i].prefix +
						temp +
						ATTRIBUTES[i].suffix +
						";";
				}
			}
		}
		output =
			output +
			"Weitere Informationen:2=Kategorie-" +
			this.xmlCleanString(categoryString) +
			"-60";
		return output;
	}

	xmlCleanString(input: string): string {
		// https://www.codetable.net/unicodecharacters
		input = input.replaceAll(" - ", " &#45 ");
		input = input.replaceAll(" -", " &#45 ");
		input = input.replaceAll("- ", "&#45 ");
		input = input.replaceAll("-/", "&#45/");
		input = input.replaceAll("-", " &#45 ");
		input = input.replaceAll(" = ", " &#61 ");
		input = input.replaceAll("= ", "&#61 ");
		input = input.replaceAll(" =", " &#61 ");
		input = input.replaceAll("=", " &#61 ");
		input = input.replaceAll(" ; ", " &#59 ");
		input = input.replaceAll(" ;", " &#59 ");
		input = input.replaceAll("; ", " &#59 ");
		input = input.replaceAll(";", "&#59 ");
		input = input.replaceAll(" : ", "	&#58 ");
		input = input.replaceAll(" :", " &#58 ");
		input = input.replaceAll(": ", "&#58 ");
		input = input.replaceAll(":", " &#58 ");
		input = input.replaceAll(/(?:\r\n|\r|\n)/g, "<br />");
		return input;
	}

	getXmlImages(): string {
		let output = "";
		for (let i in this.bilder) {
			if (Number(i) > 0) {
				output +=
					REQUESTURL +
					"image/aktiv/" +
					this.besitzer +
					"/" +
					this.id +
					"/original/" +
					this.bilder[i] +
					";";
			}
		}
		return output;
	}

	getShortName() {
		let n = 50;
		let str = "";
		if (this.name_de !== null) {
			str = this.name_de;
		}
		return str.length > n ? str.substr(0, n - 1) + " …" : str;
	}

	async readApi(id: Number) {
		const _this = this;
		const url = `${REQUESTURL}api/produkt_neu/${id}`;
		const token = localStorage.getItem(this.localStorageKey);

		await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${token}`
			}
		})
			.then(function(response) {
				if (response.ok) return response.json();
				console.error("Die Rückgabe ist nicht ok");
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.then(function(json) {
				_this.translateApi(json);
			})
			.catch(function(err) {
				console.error(`error: ${err}`);
			});
	}

	async updateApi(): Promise<boolean> {
		const _this = this;
		const token = localStorage.getItem("token");
		let response = await fetch(REQUESTURL + "api/update_product", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${token}`
			},
			body: JSON.stringify(_this)
		})
		if(response.ok) {
			return true
		} else {
			return false
		}
	}

	translateApi(json: any) {
		this.id = Number(json.prover_id);
		this.number = Number(json.prover_number);
		this.type = Number(json.prover_type);
		this.auktions_id = Number(json.prover_auk_id);
		this.los_nr = Number(json.prover_los_nr);
		this.znr = json.prover_znr;
		this.znrZahl = json.prover_znr_zahl;
		this.name_de = json.prover_name_de;
		this.jahr = json.prover_jahr;
		this.beschrieb = this.stringCheck(json.prover_beschrieb_de);
		this.langbeschrieb = this.stringCheck(json.prover_langbeschrieb_de);
		this.dateiname = json.linver_dateiname;
		this.preis = json.prover_preis;
		this.kategorie_id = Number(json.prover_kategorie);
		this.auktions_ende = json.auk_ende;
		this.besitzer = Number(json.prover_user_id);
		this.katalogwert = json.prover_preisvorschlag;
		this.gestempelt = this.true_or_false(json.prover_gestempelt);
		this.vollstempel = this.true_or_false(json.prover_vollstempel);
		this.brief = this.true_or_false(json.prover_brief);
		this.briefausschnitt = this.true_or_false(
			json.prover_briefausschnitt
		);
		this.ersttag = this.true_or_false(json.prover_ersttag);
		this.postfrisch = this.true_or_false(json.prover_postfrisch);
		this.ungebraucht = this.true_or_false(json.prover_ungebraucht);
		this.ungebraucht_oG = this.true_or_false(
			json.prover_ungebraucht_ohne_gummi
		);
		this.viererBlock = this.true_or_false(json.prover_vierer_block);
		this.einheit = this.true_or_false(json.prover_einheit);
		this.sold = this.true_or_false(json.prover_sold);
		this.luxus = this.true_or_false(json.prover_luxus);
		this.raritaet = this.true_or_false(json.prover_raritaet);

		this.category.id = Number(json.kat_id);
		this.category.level_1 = json.kat_level_1;
		this.category.level_2 = json.kat_level_2;
		this.category.level_3 = json.kat_level_3;
		this.category.level_4 = json.kat_level_4;
		
		try {
			this.bilder = json.pictures.split(", ");
		} catch(e) {
			console.log(e);
		}
	}

	true_or_false(input: any) {
		if (Number(input) === 0 || input === null) {
			return false;
		}
		return true;
	}

	stringCheck(input: any): string {
		let output: string = String(input);
		return output !== "null" ? output : "";
	}

	// Saving collection to localStorage
	save() {
		localStorage.setItem(this.localStorageKey, JSON.stringify(this));
	}
}
export default Product;
