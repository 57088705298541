import Category from "./Category";
import { REQUESTURL } from "../constants/urls";

export default class CategoryCollection {
	collection: Category[];

	constructor() {
		this.collection = [];
	}

	getXmlString(id: number): string {
		let output = "";
		let category = this.collection.find(category => category.id === id);
		if (category !== undefined) {
			output = category.getXMLCategory();
		}
		return output;
	}

	getCategoryString(id: number): string {
		let output = "";
		let category = this.collection.find(category => category.id === id);
		if (category !== undefined) {
			output = category.getCategory();
		}
		return output;
	}

	readApi(Store: any) {
		const _this = this;
		const url = REQUESTURL + "categories";

		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		})
			.then(function(response) {
				if (response.ok) return response.json();
				console.log("Die Rückgabe ist nicht ok");
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.then(function(json) {
				_this.translateApi(json);
				Store.notify();
			})
			.catch(function(err) {
				console.log(`error: ${err}`);
			});
	}

	translateApi(json: any) {
		const collection: Category[] = [];

		for (let i in json) {
			let category = new Category();
			category.id = Number(json[i].kat_id);
			category.level_1 = json[i].kat_level_1;
			category.level_2 = json[i].kat_level_2;
			category.level_3 = json[i].kat_level_3;
			category.level_4 = json[i].kat_level_4;
			category.created = json[i].kat_created_at;
			category.updated = json[i].kat_updated_at;
			collection.push(category);
		}

		this.collection = collection;
	}
}
