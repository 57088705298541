import React from "react";
import Category from "../models/Category";
import Observer from "../observerstore/Observer";
import Select from "react-select";
import Store from "../observerstore/Store";
const $ = Store.data;

type Props = {
	storeObject: any;
};

type State = {
	categoryCollection: Category[];
	categoryId: number;
};

type OptionType = {
	value: number;
	label: string;
};

export default class SelectContainer extends Observer<
	Props,
	State
> {
	constructor(props: any) {
		super(props);
		this.state = {
			categoryCollection: [],
			categoryId: 0
		};
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount(): void {
		Store.attach(this);
		$.categoryCollection.readApi(Store);
	}


	update(): void {
		this.setStateNStore({
			categoryCollection: [...$.categoryCollection.collection],
			categoryId: this.props.storeObject.kategorie_id
		});
	}

	handleChange(event: any): void {
		let value: any = Number(event.value);
		this.props.storeObject.kategorie_id = Number(value);
		this.update();
	}

	getOptions(): OptionType[] {
		let output: OptionType[] = [];
		output.push({ value: 0, label: "Keine Kategorie" });
		for (let i in this.state.categoryCollection) {
			output.push({
				value: Number(this.state.categoryCollection[i].id),
				label: this.state.categoryCollection[i].getCategory()
			});
		}
		return output;
	}

	getFromOptions(input: number): OptionType {
		let output: OptionType | undefined = this.getOptions().find(
			element => element.value === input
		);
		if (output === undefined) {
			output = { value: 0, label: "Nicht gefunden" };
		}
		return output;
	}

	render() {
		return (
			<>
				<code className="component_info">{this.constructor.name}</code>
				<div className="select-block">
					<Select
						value={this.getFromOptions(this.state.categoryId)}
						onChange={this.handleChange}
						options={this.getOptions()}
					/>
				</div>
			</>
		);
	}
}
