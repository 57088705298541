import React from "react";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import SourceProduct from "../models/SourceProduct";
//import { APIURL } from "../constants/urls";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
const $ = Store.data;

interface Props {}
interface State {
	sourceProductCollection: SourceProduct[];
}

export default class SourceProductList extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			sourceProductCollection: [],
		};
		this.handleClick = this.handleClick.bind(this);
	}

	update(): void {
		this.setStateNStoreForce({
			sourceProductCollection: [...$.sourceProductCollection.collection],
		});
	}

	handleClick(id: number): void {
		$.sourceProduct.readApi(Store, id);
	}

	getCategory(id: number): string {
		return $.categoryCollection.getCategoryString(id);
	}

	render() {
		return (
			<>
				<code className="component_info">{this.constructor.name}</code>
				{this.state.sourceProductCollection.map((p, i) => {
					//const bildname = `CHE_${p.hauser_zusatz}_${p.znr}.jpg`;
					//const bildpfad = `${APIURL}/image/referenzdatenbank/${bildname}`;
					return (
						<div
							key={i.toString()}
							className="product_list"
							onClick={e => this.handleClick(p.id)}
						>
							<Row>
								<Col xs={6} md={4}>
									<Image src={p.picturePath} />
								</Col>
								<Col xs={6} md={8}>
									<Badge variant="secondary">
										{this.getCategory(p.kategorie_id)}
									</Badge>
									<h1>{p.title_de}</h1>
									<p>{p.getKurzbeschrieb()}<br />
									{p.langbeschrieb}</p>
								</Col>
							</Row>
						</div>
					);
				})}
				{(this.state.sourceProductCollection.length === 0) && 
					<h4>Keine Produkte</h4>
				}
				
			</>
		);
	}
}
