import AllHauserAdditions from "../components/AllHauserAdditions";
import CategorySelectContainer from './CategorySelectContainer';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import SourceSearch from '../interfaces/SourceSearch';
import Form from "react-bootstrap/Form";
import React from "react";
import Row from "react-bootstrap/Row";
import SourceProduct from '../models/SourceProduct';
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
const $ = Store.data;

interface Props {}
interface State {
	sourceSearch: SourceSearch;
}

export default class SourceProductSearch extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			sourceSearch: {
				hauser_zusatz: "",
				znr: "",
				jahr: "",
				kategorie_id: 0
			}
		};

		this.handleKeypress = this.handleKeypress.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
	}

	update() {
		let sourceSearch = Object.assign({}, $.sourceSearch);
		this.setStateNStore({
			sourceSearch: sourceSearch
		});
	}

	handleKeypress(e: any) {
		if (e.key === 'Enter') this.handleSubmit();
	}

	handleSubmit() {
		const { hauser_zusatz } = $.sourceSearch;
		const { znr } = $.sourceSearch;
		const { jahr } = $.sourceSearch;
		const { kategorie_id } = $.sourceSearch;
		$.sourceProductCollection.readApi(
			Store,
			hauser_zusatz,
			znr,
			jahr,
			kategorie_id
		);
		$.sourceProduct = new SourceProduct();
	}

	handleSelect<K extends keyof SourceSearch>(event: any) {
		event.preventDefault();
		const value: SourceSearch[K] = event.target.value;
		const id: K = event.target.id;
		$.sourceSearch[id] = value;
		Store.notify();
	}

	handleInput<K extends keyof SourceSearch>(event: any) {
		event.preventDefault();
		const value: SourceSearch[K] = event.target.value;
		const id: K = event.target.id;
		$.sourceSearch[id] = value;
		Store.notify();
	}

	render() {
		return (
			<>
				<code className="component_info">{this.constructor.name}</code>

					<Row>
						<Col xs={4}>
							<Form.Group
								controlId="hauser_zusatz"
								onChange={this.handleSelect}
							>
								<AllHauserAdditions />
							</Form.Group>
						</Col>
						<Col xs={4}>
							<Form.Group controlId="znr">
								<Form.Control
									type="text"
									onChange={this.handleInput}
									onKeyPress={this.handleKeypress}
									value={
										this.state.sourceSearch.znr ||
										""
									}
									placeholder="Z-Nr. eingeben"
								/>
							</Form.Group>
						</Col>
						<Col xs={4}>
							<Form.Group controlId="jahr">
								<Form.Control
									type="text"
									onChange={this.handleInput}
									onKeyPress={this.handleKeypress}
									value={
										this.state.sourceSearch.jahr ||
										""
									}
									placeholder="Jahr eingeben"
								/>
							</Form.Group>
						</Col>
						<Col xs={12}>
							<CategorySelectContainer storeObject={$.sourceSearch} />
						</Col>
					</Row>

					<Button variant="primary" type="submit" onClick={this.handleSubmit}>
						Suchen
					</Button>
			</>
		);
	}
}
