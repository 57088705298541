import React from "react";
import Pagination from "react-bootstrap/Pagination";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
//import Mousetrap from 'mousetrap';
const $ = Store.data;

interface Props {}
interface State {
	anzahl: number;
	seite: number;
	type: number;
	total: number;
	paginationArray: PaginationItem[];
	disableFirst: boolean;
	disablePrev: boolean;
	disableNext: boolean;
	disableLast: boolean;
}

interface PaginationItem {
	id: number;
	state: string;
}

export default class Pagina extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			anzahl: 0,
			seite: 0,
			type: 0,
			total: 0,
			paginationArray: [],
			disableFirst: false,
			disablePrev: false,
			disableNext: false,
			disableLast: false
		};
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}

	componentDidMount() {
		this.handleShortcuts(true);
		Store.attach(this);
	}

	componentWillUnmount() {
		this.handleShortcuts(false);
		Store.detach(this);
	}

	handleShortcuts(action: boolean) {
		if (action) {
			document.addEventListener("keyup", this.shortcut);
		} else {
			document.removeEventListener("keyup", this.shortcut);
		}
	}

	shortcut(e: any) {
		let button: any = "";
		switch (e.code) {
			case "ArrowLeft":
				button = document.getElementById("paginaPrev");
				if (button !== null) button.click();
				break;
			case "ArrowRight":
				button = document.getElementById("paginaNext");
				if (button !== null) button.click();
				break;
		}
	}

	update() {
		this.setStateNStore({
			anzahl: $.url.anzahl,
			seite: $.url.seite,
			type: $.url.type,
			total: this.getTotal(),
			paginationArray: this.createPaginationArray(
				$.url.seite,
				this.getTotal()
			),
			disableFirst: this.getDisableFirst(),
			disablePrev: this.getDisableFirst(),
			disableNext: this.getDisableLast(),
			disableLast: this.getDisableLast()
		});
	}

	getDisableFirst(): boolean {
		return $.url.seite === 1 ? true : false;
	}

	getDisableLast(): boolean {
		return $.url.seite >= this.getTotal() ? true : false;
	}

	createPaginationArray(
		currentPage: number,
		total: number
	): PaginationItem[] {
		let temp: PaginationItem[] = [];
		temp.push({ id: currentPage, state: "active" });
		if (currentPage + 1 <= total) {
			temp = this.addFront(temp, total);
		} else {
			temp = this.addBack(temp);
		}
		if (currentPage + 2 <= total) {
			temp = this.addFront(temp, total);
		} else {
			temp = this.addBack(temp);
		}
		if (currentPage + 3 <= total) {
			temp = this.addFront(temp, total);
		} else {
			temp = this.addBack(temp);
		}
		if (currentPage - 1 < 1) {
			temp = this.addFront(temp, total);
		} else {
			temp = this.addBack(temp);
		}
		if (currentPage - 2 < 1) {
			temp = this.addFront(temp, total);
		} else {
			temp = this.addBack(temp);
		}
		if (currentPage - 3 < 1) {
			temp = this.addFront(temp, total);
		} else {
			temp = this.addBack(temp);
		}
		temp.sort(function(a, b) {
			return a.id - b.id;
		});
		return temp;
	}

	addFront(array: PaginationItem[], total: number): PaginationItem[] {
		let max: number = Math.max.apply(
			Math,
			array.map(function(o) {
				return o.id;
			})
		);
		max++;
		if (max <= total) {
			array.push({ id: max, state: "" });
		}
		return array;
	}

	previous() {
		let seite = this.state.seite - 1;
		if (seite > 0) {
			window.location.href = `#/produktuebersicht/${this.state.type}/${this.state.anzahl}/${seite}`;
		}
	}

	next() {
		let seite = this.state.seite + 1;
		if (seite <= this.state.total) {
			window.location.href = `#/produktuebersicht/${this.state.type}/${this.state.anzahl}/${seite}`;
		}
	}

	addBack(array: PaginationItem[]): PaginationItem[] {
		let min: number = Math.min.apply(
			Math,
			array.map(function(o) {
				return o.id;
			})
		);
		min--;
		if (min > 0) {
			array.push({ id: min, state: "" });
		}
		return array;
	}

	getTotal() {
		return Math.ceil($.productCollection.count / $.url.anzahl);
	}

	render() {
		return (
			<>
				<code className="component_info">{this.constructor.name}</code>
				<Pagination>
					<Pagination.First
						disabled={this.state.disableFirst}
						onClick={() =>
							(window.location.href = `#/produktuebersicht/${this.state.type}/${this.state.anzahl}/1`)
						}
					/>
					<Pagination.Prev
						disabled={this.state.disablePrev}
						onClick={this.previous}
						id="paginaPrev"
					/>

					{this.state.paginationArray.map(o => (
						<Pagination.Item
							key={o.id}
							onClick={() =>
								(window.location.href = `#/produktuebersicht/${this.state.type}/${this.state.anzahl}/${o.id}`)
							}
							active={o.state === "active"}
						>
							{o.id}
						</Pagination.Item>
					))}

					<Pagination.Next
						disabled={this.state.disableNext}
						onClick={this.next}
						id="paginaNext"
					/>
					<Pagination.Last
						disabled={this.state.disableLast}
						onClick={() =>
							(window.location.href = `#/produktuebersicht/${this.state.type}/${this.state.anzahl}/${this.state.total}`)
						}
					/>
				</Pagination>
			</>
		);
	}
}
