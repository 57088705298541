import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import AuctionContainer from "../containers/AuctionContainer";
import { RouteComponentProps } from "react-router";

interface MatchParams {
	id: string;
}
interface Props extends RouteComponentProps<MatchParams> {}
interface State {
	show: boolean;
}

export default class AuctionView extends Component<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			show: false
		};
		this.alarm = this.alarm.bind(this);
	}

	alarm() {
		this.setState({ show: true });
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		setTimeout(() => {
			this.setState({ show: false });
		}, 2500);
	}

	render() {
		return (
			<>
				<code className="component_info">AuctionView.js</code>
				<Alert variant="success" show={this.state.show}>
					Update war erfolgreich
				</Alert>
				<Container>
					<Row>
						<Col sm={3} />
						<Col sm={6} className="white">
							<AuctionContainer
								id={Number(this.props.match.params.id)}
							/>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}
