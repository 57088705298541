import React from "react";
import ImageGallery from "react-image-gallery";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import TypeSelectContainer from "./TypeSelectContainer";
import UserSelectContainerDetail from "./UserSelectContainerDetail";
import AuctionSelectContainer from "./AuctionSelectContainer";
import CategorySelectContainer from "./CategorySelectContainer";
import User from "../models/User";
import UserCollection from "../models/UserCollection";
import Product from "../models/Product";
import Error from "../components/Error";
import ProductButtons from "../components/ProductButtons";
import { APIURL } from "../constants/urls";
import Observer from "../observerstore/Observer";
import Store from "../observerstore/Store";
const $ = Store.data;

interface Props {
	id: number;
}

interface State {
	user: User;
	userCollection: UserCollection;
	product: Product;
	pictureArray: any[];
}

export default class ProductContainer extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			user: new User(),
			userCollection: new UserCollection(),
			product: new Product(),
			pictureArray: [],
		};
		this.handleChangeText = this.handleChangeText.bind(this);
		this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
		this.handleChangeSwitch = this.handleChangeSwitch.bind(this);
		this.getPictureArray = this.getPictureArray.bind(this);
	}

	update() {
		this.setStateNStoreForce({
			product: $.product,
			userCollection: $.userCollection,
			pictureArray: this.getPictureArray(),
		});
	}

	async componentDidMount() {
		Store.attach(this);
		await $.product.readApi(this.props.id);
		Store.notify();
		this.setState({
			pictureArray: this.getPictureArray(),
		});
	}

	async componentDidUpdate(prevProps: Props) {
		if (this.props.id !== prevProps.id) {
			await $.product.readApi(this.props.id);
			Store.notify();
		}
	}

	private handleChangeText<K extends keyof Product>(event: any) {
		const value: Product[K] = event.target.value;
		const key: K = event.target.id;
		let product: Product = this.state.product;
		product[key] = value;
		this.setStateNStoreForce({
			product: product,
		});
	}

	handleChangeCheckbox<K extends keyof Product>(event: any) {
		const target = event.target;
		const value: Product[K] =
			target.type === "checkbox" ? target.checked : target.value;
		const key: K = target.id;
		let product: Product = this.state.product;
		product[key] = value;
		this.setStateNStoreForce({
			product: product,
		});
	}

	private handleChangeSwitch<K extends keyof Product>(event: any) {
		const target = event.target;
		const value: Product[K] =
			target.type === "checkbox" ? target.checked : target.value;
		const key: K = target.id;
		let product: Product = this.state.product;
		product[key] = value;
		this.setStateNStoreForce({
			product: product,
		});
	}

	getPictureArray(): any[] {
		const thumbnail = `${APIURL}/image/aktiv/${this.state.product.besitzer}/${this.props.id}/100x100/`;
		const original = `${APIURL}/image/aktiv/${this.state.product.besitzer}/${this.props.id}/1600x900/`;
		let bilder: any[] = this.state.product.bilder.map((p) => {
			return { original: original + p, thumbnail: thumbnail + p };
		});
		return bilder;
	}

	private formatProductNumber(input: number) {
		return ("0000" + input).slice(-4);
	}

	private getArticleNumber(): string {
		let product: Product = this.state.product;
		let user: User | undefined = this.state.userCollection.get(
			product.besitzer
		);
		if (user) {
			return user.number + "." + this.formatProductNumber(product.number);
		}
		return "000.0000";
	}

	render() {
		return (
			<>
				<code className="component_info">ProductContainer.js</code>
				<h1>
					<span style={{ color: "grey" }}>Artikel</span>{" "}
					{this.getArticleNumber()}
				</h1>
				<hr />
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						marginBottom: "15px",
					}}
				>
					<ProductButtons />
					<Form>
						<Form.Check
							type="switch"
							id="sold"
							checked={this.state.product.sold || false}
							onChange={this.handleChangeSwitch}
							label="Verkauft"
						/>
					</Form>
				</div>

				{false && <Error />}
				<ImageGallery
					items={this.state.pictureArray}
					showPlayButton={false}
				/>
				<hr />

				<Form.Group controlId="langbeschrieb">
					<Form.Label>Beschrieb individuell</Form.Label>
					<Form.Control
						value={this.state.product.langbeschrieb || ""}
						onChange={this.handleChangeText}
						as="textarea"
						rows={4}
						placeholder="Text eingeben …"
						className="form-control-special"
					/>
				</Form.Group>
				{this.state.user.admin !== true && (
					<div>
						<UserSelectContainerDetail />
						<Form.Label>Produkt-Nummer</Form.Label>
						<Form.Group controlId="number">
							<Form.Control
								value={String(this.state.product.number) || ""}
								onChange={this.handleChangeText}
								type="number"
								placeholder="Text eingeben …"
							/>
						</Form.Group>
						<Form.Label>Typ</Form.Label>
						<TypeSelectContainer />
						<Form.Label>Kategorie</Form.Label>
						<CategorySelectContainer storeObject={$.product} />
						{this.state.product.type === 1 && (
							<div>
								<AuctionSelectContainer />
								<Form.Group controlId="los_nr">
									<Form.Label>Los-Nummer</Form.Label>
									<Form.Control
										value={
											String(this.state.product.los_nr) ||
											""
										}
										onChange={this.handleChangeText}
										type="number"
										placeholder="Text eingeben …"
									/>
								</Form.Group>
							</div>
						)}
					</div>
				)}
				<Form.Group controlId="znr" className="no-margin-bottom">
					<Form.Label>Zumstein-Nummer</Form.Label>
					<Form.Control
						value={this.state.product.znr || ""}
						onChange={this.handleChangeText}
						type="text"
						placeholder="Text eingeben …"
					/>
				</Form.Group>
				<Form.Group controlId="znrZahl">
					<Form.Control
						value={this.state.product.znrZahl || ""}
						onChange={this.handleChangeText}
						type="text"
						placeholder="Text eingeben …"
						className="form-control-danger"
					/>
				</Form.Group>
				<Form.Group controlId="name_de">
					<Form.Label>Titel (deutsch)</Form.Label>
					<Form.Control
						value={this.state.product.name_de || ""}
						onChange={this.handleChangeText}
						type="text"
						placeholder="Text eingeben …"
					/>
				</Form.Group>
				<Form.Group controlId="jahr">
					<Form.Label>Jahr</Form.Label>
					<Form.Control
						value={this.state.product.jahr || ""}
						onChange={this.handleChangeText}
						type="text"
						placeholder="Text eingeben …"
					/>
				</Form.Group>
				<Form.Group controlId="beschrieb">
					<Form.Label>Beschrieb</Form.Label>
					<Form.Control
						value={this.state.product.beschrieb || ""}
						onChange={this.handleChangeText}
						as="textarea"
						rows={2}
						placeholder="Text eingeben …"
					/>
				</Form.Group>
				<Form.Group controlId="katalogwert">
					<Form.Label>Katalogwert</Form.Label>
					<Form.Control
						value={this.state.product.katalogwert || ""}
						onChange={this.handleChangeText}
						type="text"
						placeholder="Text eingeben …"
					/>
				</Form.Group>
				<Form.Group controlId="preis">
					<Form.Label>Verkaufspreis</Form.Label>
					<Form.Control
						value={this.state.product.preis || ""}
						onChange={this.handleChangeText}
						type="text"
						placeholder="Text eingeben …"
					/>
				</Form.Group>
				<Row>
					<Col sm="6">
						<Form.Group controlId="gestempelt">
							<Form.Check
								checked={this.state.product.gestempelt || false}
								onChange={this.handleChangeCheckbox}
								type="checkbox"
								label="Gestempelt"
							/>
						</Form.Group>
						<Form.Group controlId="vollstempel">
							<Form.Check
								checked={
									this.state.product.vollstempel || false
								}
								onChange={this.handleChangeCheckbox}
								type="checkbox"
								label="Vollstempel"
							/>
						</Form.Group>
						<Form.Group controlId="brief">
							<Form.Check
								checked={this.state.product.brief || false}
								onChange={this.handleChangeCheckbox}
								type="checkbox"
								label="Brief"
							/>
						</Form.Group>
						<Form.Group controlId="briefausschnitt">
							<Form.Check
								checked={
									this.state.product.briefausschnitt || false
								}
								onChange={this.handleChangeCheckbox}
								type="checkbox"
								label="Briefstück"
							/>
						</Form.Group>
						<Form.Group controlId="ersttag">
							<Form.Check
								checked={this.state.product.ersttag || false}
								onChange={this.handleChangeCheckbox}
								type="checkbox"
								label="Ersttag"
							/>
						</Form.Group>
						<Form.Group controlId="postfrisch">
							<Form.Check
								checked={this.state.product.postfrisch || false}
								onChange={this.handleChangeCheckbox}
								type="checkbox"
								label="Postfrisch"
							/>
						</Form.Group>
					</Col>
					<Col sm="6">
						<Form.Group controlId="ungebraucht">
							<Form.Check
								checked={
									this.state.product.ungebraucht || false
								}
								onChange={this.handleChangeCheckbox}
								type="checkbox"
								label="Ungebraucht"
							/>
						</Form.Group>
						<Form.Group controlId="ungebraucht_oG">
							<Form.Check
								checked={
									this.state.product.ungebraucht_oG || false
								}
								onChange={this.handleChangeCheckbox}
								type="checkbox"
								label="Ungebraucht ohne Gummi"
							/>
						</Form.Group>
						<Form.Group controlId="viererBlock">
							<Form.Check
								checked={
									this.state.product.viererBlock || false
								}
								onChange={this.handleChangeCheckbox}
								type="checkbox"
								label="Vierer-Block"
							/>
						</Form.Group>
						<Form.Group controlId="einheit">
							<Form.Check
								checked={this.state.product.einheit || false}
								onChange={this.handleChangeCheckbox}
								type="checkbox"
								label="Einheit"
							/>
						</Form.Group>
						<Form.Group controlId="luxus">
							<Form.Check
								checked={this.state.product.luxus || false}
								onChange={this.handleChangeCheckbox}
								type="checkbox"
								label="Luxus"
							/>
						</Form.Group>
						<Form.Group controlId="raritaet">
							<Form.Check
								checked={this.state.product.raritaet || false}
								onChange={this.handleChangeCheckbox}
								type="checkbox"
								label="Rarität"
							/>
						</Form.Group>
					</Col>
				</Row>
			</>
		);
	}
}
