import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";

type MainProps = {
	root: number;
};

export default class Navi extends Component<MainProps, {}> {
	render() {
		return (
			<>
				<Navbar bg="dark" variant="dark" expand="lg">
					<Navbar.Brand href="#">R&J universal AG</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="mr-auto">
							<NavDropdown
								title="Produkte"
								id="basic-nav-dropdown"
							>
								<NavDropdown.Item href="#/produktuebersicht/0/10/1">
									Alle Produkte
								</NavDropdown.Item>
								<NavDropdown.Item href="#/produktuebersicht/1/10/1">
									Auktions-Produkte
								</NavDropdown.Item>
								<NavDropdown.Item href="#/produktuebersicht/2/10/1">
									Shop-Produkte
								</NavDropdown.Item>
							</NavDropdown>
							{/*<Nav.Link href="#/produktuebersicht/0/10/1">
								Meine Produkte
          </Nav.Link> */}
							{this.props.root !== 0 && (
								<NavDropdown
									title="Auktionen"
									id="basic-nav-dropdown"
								>
									<NavDropdown.Item href="#/auktionuebersicht/20/1">
										Alle Auktionen
									</NavDropdown.Item>
									<NavDropdown.Item href="#/auktionerstellen">
										Auktion erstellen
									</NavDropdown.Item>
								</NavDropdown>
							)}
						</Nav>
						<Nav>
							<Nav.Link href="#/logout">Abmelden</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
				<code className="component_info">Navbar</code>
			</>
		);
	}
}
