import React from "react";
import SourceProductList from "../components/SourceProductList";
import SourceProductDetail from "../components/SourceProductDetail";
import GroupDetail from "../components/GroupDetail";
import SourceProductSearch from "./SourceProductSearch";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import SourceProduct from "../models/SourceProduct";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
const $ = Store.data;

interface Props {}
interface State {
	sourceProduct: SourceProduct;
}

export default class SourceProductContainer extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			sourceProduct: new SourceProduct()
		};
	}

	componentDidMount() {
		Store.attach(this);
	}

	update() {
		let sourceProduct = Object.assign({}, $.sourceProduct);
		this.setStateNStore({
			sourceProduct: sourceProduct
		});
	}

	render() {
		return (
			<>
				<code className="component_info">{this.constructor.name}</code>
				<h1>Quell-Produkt</h1>
				<hr />
				<SourceProductSearch />
				<hr />

				{this.state.sourceProduct.id === 0 && <SourceProductList />}

				{this.state.sourceProduct.id !== 0 && (
					<>
						<Tabs
							defaultActiveKey="produkt"
							id="uncontrolled-tab-example"
							className="tabs-sourceproduct"
						>
							<Tab eventKey="produkt" title="Produkt">
								<SourceProductDetail />
							</Tab>
							<Tab eventKey="gruppe" title="Satz">
								<GroupDetail />
							</Tab>
						</Tabs>
					</>
				)}
			</>
		);
	}
}
