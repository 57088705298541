import React from "react";
import Product from "../models/Product";
import Button from "react-bootstrap/Button";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
import Form from "react-bootstrap/Form";
import TypesSelectContainer from "./TypesSelectContainer";
const $ = Store.data;

type Props = { };
type State = {
};

export default class ProductsContainer extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.handleChangeText = this.handleChangeText.bind(this);
		this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		Store.attach(this);
		let productSelection = $.productCollection.getSelectedProducts();
		if (productSelection.length === 0) {
			this.redirect();
		}
	}

	redirect() {
		window.location.href = "#/produktuebersicht/0/10/1";
	}

	activateMessage() {
		$.alertGood = true;
		Store.notify();
	}

	handleSubmit() {
		let array = $.productCollection.getSelectedProducts();
		for (let i in array) {
			array[i].updateApi();
		}
		this.activateMessage();
	}

	handleChangeText<K extends keyof Product>(event: any) {
		let value: Product[K] = event.target.value;
		let key: K = event.target.id;
		let array: Product[] = $.productCollection.getSelectedProducts();
		for (let i in array) {
			array[i][key] = value;
		}
	}

	handleChangeCheckbox<K extends keyof Product>(event: any) {
		// TODO: Vermutlich ist das schon gemacht, wurde noch nicht getestet
		let target = event.target;
		let value: Product[K] =
			target.type === "checkbox" ? target.checked : target.value;
		let key: K = target.id;
		let array: Product[] = $.productCollection.getSelectedProducts();
		for (let i in array) {
			array[i][key] = value;
		}
	}

	render() {
		return (
			<>
				<code className="component_info">ProductsContainer.js</code>
				<h1>Mehrere Produkte bearbeiten</h1>
				<hr />
				<TypesSelectContainer />

				<Form.Group controlId="auktions_id">
					<Form.Label>Auktions-ID</Form.Label>
					<Form.Control
						onChange={this.handleChangeText}
						type="number"
						placeholder="Text eingeben …"
					/>
				</Form.Group>
				<Form.Group controlId="znr">
					<Form.Label>Zumstein-Nummer</Form.Label>
					<Form.Control
						onChange={this.handleChangeText}
						type="text"
						placeholder="Text eingeben …"
					/>
				</Form.Group>
				<Form.Group controlId="name_de">
					<Form.Label>Name (deutsch)</Form.Label>
					<Form.Control
						onChange={this.handleChangeText}
						type="text"
						placeholder="Text eingeben …"
					/>
				</Form.Group>
				<Form.Group controlId="jahr">
					<Form.Label>Jahr</Form.Label>
					<Form.Control
						onChange={this.handleChangeText}
						type="text"
						placeholder="Text eingeben …"
					/>
				</Form.Group>
				<Form.Group controlId="preis">
					<Form.Label>Verkaufspreis</Form.Label>
					<Form.Control
						onChange={this.handleChangeText}
						type="text"
						placeholder="Text eingeben …"
					/>
				</Form.Group>

				<Button
					variant="primary"
					type="submit"
					onClick={this.handleSubmit}
				>
					Speichern
				</Button>
			</>
		);
	}
}
