import Product from "../models/Product";
import { REQUESTURL } from "../constants/urls";

export default class Auction {
	id: number;
	name: string;
	start: string;
	ende: string;
	beschrieb: string;
	aktiv: boolean;
	created: string;
	updated: string;
	products: Product[];
	selected: boolean;

	constructor(
		id?: number,
		name?: string,
		start?: string,
		ende?: string,
		beschrieb?: string,
		aktiv?: boolean,
		created?: string,
		updated?: string
	) {
		this.id = id || 0;
		this.name = name || "";
		this.start = start || "";
		this.ende = ende || "";
		this.beschrieb = beschrieb || "";
		this.aktiv = aktiv || false;
		this.created = created || "";
		this.updated = updated || "";
		this.products = [];
		this.selected = false;
	}

	toString() {
		return `${this.id} – ${this.name}`;
	}

	getHighlights() {
		return this.products.filter(product => product.highlight !== 0);
	}

	getHighlight(highlightNumber: number): Product {
		let product = this.products.find(
			product => product.highlight === highlightNumber
		);
		if (product === undefined) {
			return new Product();
		} else {
			return product;
		}
	}

	getNonHighlightsArray(highlightNumber: number): Product[] {
		let products = this.products.filter(product => product.highlight === 0);
		let highlight = this.getHighlight(highlightNumber);
		if(highlight.id !== 0) {
			products.push(highlight)
		}
		return products;
	}

	setHighlight(productId: number, highlightNumber: number) {
		for (let i in this.products) {
			if (this.products[i].highlight === highlightNumber) {
				this.products[i].highlight = 0;
			}
		}
		for (let i in this.products) {
			if (this.products[i].id === productId) {
				this.products[i].highlight = highlightNumber;
			}
		}
	}

	readApi(Store: any, id: number) {
		const _this = this;

		fetch(`${REQUESTURL}auktion/${id}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		})
			.then(function(response) {
				if (response.ok) return response.json();
				console.log("Die Rückgabe ist nicht ok");
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.then(function(json) {
				_this.translateApi(json);
				Store.notify();
			})
			.catch(function(err) {
				console.log(`error: ${err}`);
			});
	}

	updateApi() {
		const _this = this;
		fetch(REQUESTURL + "update_auction", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(_this)
		})
			.then(function(response) {
				if (response.ok) return response.json();
				console.log("Die Rückgabe ist nicht ok");
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.catch(function(err) {
				console.log(`error: ${err}`);
			});
	}

	createApi() {
		const _this = this;
		fetch(REQUESTURL + "create_auction", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(_this)
		})
			.then(function(response) {
				if (response.ok) return response.json();
				console.log("Die Rückgabe ist nicht ok");
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.then(function(response) {
				window.location.href = "#/auktionuebersicht/20/1";
			})
			.catch(function(err) {
				// Hier Fehlerbehandlung
				console.log(`error: ${err}`);
			});
	}

	translateApi(json: any) {
		const products: Product[] = [];
		json[0].produkte.map((p: any, i: number) => {
			let product = new Product();
			product.id = Number(p.prover_id);
			product.auktions_id = Number(p.prover_auk_id);
			product.los_nr = Number(p.prover_los_nr);
			product.znr = p.prover_znr;
			product.name_de = p.prover_name_de;
			product.jahr = p.prover_jahr;
			product.beschrieb = p.prover_beschrieb_de;
			product.dateiname = p.linver_dateiname;
			product.preis = p.prover_preisvorschlag;
			product.auktions_ende = p.auk_ende;
			product.highlight = Number(p.aukhig_position);
			product.besitzer = Number(p.aukhig_position);
			products[i] = product;
			return 0;
		});

		this.id = Number(json[0].auk_id);
		this.name = json[0].auk_name;
		this.start = json[0].auk_start;
		this.ende = json[0].auk_ende;
		this.beschrieb = json[0].auk_beschrieb;
		this.aktiv = Boolean(Number(json[0].auk_aktiv));
		this.created = json[0].created_at;
		this.updated = json[0].updated_a;
		this.products = products;
	}

	// Saving collection to localStorage
	//save() {
	//localStorage.setItem(
	//this.localStorageKey,
	//JSON.stringify(this.auction)
	//);
	//}
}
