import React from "react";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
const $ = Store.data;

interface Props {}
interface State {
	value: string;
}

export default class ProductSearchContainer extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			value: ""
		};

		this.search = this.search.bind(this);
		this.handleKeypress = this.handleKeypress.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	update() {
		if ($.search.searchTerm !== "0" && $.search.searchTerm !== "%") {
			this.setStateNStore({
				value: $.search.searchTerm
			});
		}
	}

	handleChange(event: any) {
		this.setState({ value: event.target.value });
	}

	handleKeypress(e: any) {
		if (e.key === "Enter") this.search();
	}

	search() {
		let value: string = this.state.value;
		if (value === "") {
			value = "0";
		}
		$.search.searchTerm = value;
		$.productCollection.readApi(
			Store,
			$.url.anzahl,
			$.url.seite,
			$.search.searchTerm,
			$.search.auctionId,
			$.search.sold,
			$.search.userId,
			$.search.sortColumn,
			$.search.sortOrder,
			$.url.type
		);
	}

	render() {
		return (
			<>
				<code className="component_info">
					ProductSearchContainer.js
				</code>
				<InputGroup className="mb-3">
					<InputGroup.Append>
						<Button variant="primary" onClick={this.search}>
							Suchen
						</Button>
					</InputGroup.Append>
					<FormControl
						value={this.state.value}
						onChange={this.handleChange}
						onKeyPress={this.handleKeypress}
						type="text"
						placeholder="Suche"
					/>
				</InputGroup>
			</>
		);
	}
}
