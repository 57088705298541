import React from "react";
import Navi from "./Navbar";
import User from "../models/User";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
const $ = Store.data;

interface Props {}
interface State {
	user: User;
}

export default class Navigation extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			user: new User()
		};
	}

	componentDidMount() {
		Store.attach(this);
		$.user.checkUser(Store);
	}

	update() {
		let user = Object.assign({}, $.user);
		this.setStateNStore({
			user: user
		});
	}

	render() {
		return (
			<>
				{this.state.user.id !== 0 && (
					<Navi root={this.state.user.root} />
				)}
			</>
		);
	}
}
