export const ATTRIBUTES = [
	{
		name: "znr",
		prefix: "Allgemein:1=SBK-",
		suffix: "-10"
	},
	{
		name: "znrZahl",
		prefix: "Allgemein:1=SBKZahl-",
		suffix: "-10"
	},
	{
		name: "jahr",
		prefix: "Allgemein:1=Jahr-",
		suffix: "-1"
	},
	{
		name: "katalogwert",
		prefix: "Weitere Informationen:2=Katalogwert-",
		suffix: "-40"
	},
	{
		name: "gestempelt",
		prefix: "Zustand:3=Gestempelt-",
		suffix: "-50"
	},
	{
		name: "vollstempel",
		prefix: "Zustand:3=Vollstempel-",
		suffix: "-60"
	},
	{
		name: "brief",
		prefix: "Zustand:3=Brief-",
		suffix: "-70"
	},
	{
		name: "briefausschnitt",
		prefix: "Zustand:3=Briefstück-",
		suffix: "-80"
	},
	{
		name: "ersttag",
		prefix: "Zustand:3=Ersttag-",
		suffix: "-90"
	},
	{
		name: "postfrisch",
		prefix: "Zustand:3=Postfrisch-",
		suffix: "-100"
	},
	{
		name: "ungebraucht",
		prefix: "Zustand:3=Ungebraucht-",
		suffix: "-110"
	},
	{
		name: "ungebraucht_oG",
		prefix: "Zustand:3=Ungebraucht ohne Gummi-",
		suffix: "-120"
	},
	{
		name: "viererBlock",
		prefix: "Zustand:3=Viererblock-",
		suffix: "-130"
	},
	{
		name: "einheit",
		prefix: "Zustand:3=Einheit-",
		suffix: "-150"
	},
	{
		name: "luxus",
		prefix: "Zustand:3=Luxus-",
		suffix: "-160"
	},
	{
		name: "raritaet",
		prefix: "Zustand:3=Rarität-",
		suffix: "-170"
	},
	{
		name: "beschrieb",
		prefix: "Weitere Informationen:2=Beschrieb-",
		suffix: "-20"
	},
	{
		name: "langbeschrieb",
		prefix: "Weitere Informationen:2=Langbeschrieb-",
		suffix: "-30"
	},
];
