import React from "react";
import Product from "../models/Product";
import Store from "../observerstore/Store";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Observer from "../observerstore/Observer";
const $ = Store.data;
const FontAwesome = require("react-fontawesome");

interface Props {}
interface State {
	product: Product;
	productPrev: Product;
	productNext: Product;
}

export default class ProductButtons extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			product: new Product(),
			productPrev: new Product(),
			productNext: new Product(),
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handlePrevProduct = this.handlePrevProduct.bind(this);
		this.handleNextProduct = this.handleNextProduct.bind(this);
	}

	async componentDidMount() {
		Store.attach(this);
	}

	update() {
		this.setStateNStoreForce({
			product: $.product,
		});
		this.updateNavigationProducts();
	}

	async updateNavigationProducts() {
		let prev: Product = new Product();
		let next: Product = new Product();

		if (this.state.product.id !== 0) {
			await prev.readApi(this.state.product.id - 1);
			await next.readApi(this.state.product.id + 1);
			this.setState({
				productPrev: prev,
				productNext: next,
			});
		}
	}

	async handleSubmit() {
		await $.product.updateApi();
		this.activateMessage();
	}

	async handlePrevProduct() {
		if (this.state.productPrev.id) {
			//this.handleSubmit()
			window.location.href = `#/produkt/${this.state.productPrev.id}`;
		}
	}

	async handleNextProduct() {
		if (this.state.productNext.id) {
			//this.handleSubmit()
			window.location.href = `#/produkt/${this.state.productNext.id}`;
		}
	}

	handleCancel() {
		window.location.href = "#/produktuebersicht/0/10/1";
	}

	activateMessage() {
		$.alertGood = true;
		Store.notify();
	}

	render() {
		return (
			<div>
				<Button
					size="sm"
					variant="primary"
					onClick={this.handleSubmit}
					id="buttonSave"
					style={{ marginRight: 5 }}
				>
					Speichern
				</Button>
				<ButtonGroup aria-label="Basic example" size="sm">
					<Button
						variant="outline-primary"
						onClick={this.handlePrevProduct}
						id="buttonSave"
						style={{ marginRight: 2 }}
						disabled={Boolean(!this.state.productPrev.id)}
					>
						<FontAwesome name="arrow-left" />
					</Button>
					<Button
						variant="outline-primary"
						style={{ marginRight: 2 }}
						onClick={this.handleCancel}
						id="buttonCancel"
					>
						Abbrechen
					</Button>
					<Button
						variant="outline-primary"
						onClick={this.handleNextProduct}
						id="buttonSave"
						disabled={Boolean(!this.state.productNext.id)}
					>
						<FontAwesome name="arrow-right" />
					</Button>
				</ButtonGroup>
			</div>
		);
	}
}
