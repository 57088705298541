import { REQUESTURL } from "../constants/urls";

export default class User {
	id: number;
	number: number;
	vorname: string;
	nachname: string;
	username: string;
	admin: boolean;
	root: number;
	email: string;
	created: string;
	updated: string;
	localStorageKey: string;
	token: string;

	constructor() {
		this.id = 0;
		this.number = 0;
		this.vorname = "";
		this.nachname = "";
		this.username = "";
		this.admin = false;
		this.root = 0;
		this.email = "";
		this.created = "";
		this.updated = "";
		this.localStorageKey = "token";
		this.token = "";
	}

	getFullName(): string {
		return this.vorname + ' ' + this.nachname;
	}

	async loginUser(username: string, password: string) {
		let header = {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				username,
				password
			})
		}
		let url = REQUESTURL + "login/users"

		let response = await fetch(url, header)
		let data = await response.json()

		console.error(data.error);
		if(data.error === true) {
			console.error("Login hat nicht funktioniert.")
			this.deleteLocalStorage();
			return false;
		} else {
			this.translateApi(data);
			this.saveToLocalStorage();
			return true;
		}
	}

	async checkUser_neu_nicht_verwendet(Store: any) {
		const token = localStorage.getItem(this.localStorageKey);

		if(token) {
			let url = REQUESTURL + "api/user";
			let header = {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					authorization: `Bearer ${token}`,
				},
			};

			let response = await fetch(url, header);
			let data = await response.json();
			this.translateApi(data);
		}
	
	}

	checkUser(Store: any) {
		const _this = this;
		const token = localStorage.getItem(this.localStorageKey);

		fetch(REQUESTURL + "api/user", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${token}`
			}
		})
			.then(function(response) {
				if (response.ok) return response.json();
				window.location.href = "#/";
				throw new Error();
			})
			.then(function(json) {
				_this.translateApi(json);
				Store.notify();
			})
			.catch(function(err) {
				console.error(err);
			});
	}

	translateApi(json: any) {
		this.id = Number(json.id);
		this.vorname = json.vorname;
		this.nachname = json.nachname;
		this.username = json.username;
		this.admin = Boolean(json.admin);
		this.root = Number(json.root);
		this.email = json.email;
		this.created = json.created;
		this.updated = json.update;
		this.token = json.token;
	}

	saveToLocalStorage() {
		localStorage.setItem(this.localStorageKey, this.token);
	}

	public deleteLocalStorage(): void {
		localStorage.clear();

	}

}
