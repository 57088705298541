import Error from "../components/Error";
import Observer from "../observerstore/Observer";
import Pagina from "../components/Pagina";
import ProductSearchContainer from "./ProductSearchContainer";
import ProductSwitchContainer from "./ProductSwitchContainer";
import XmlDownloadButton from "../components/XmlDownloadButton";
import ProductSelectContainer from "./ProductSelectContainer";
import ProductCollectionTable from "../components/ProductCollectionTable";
import React from "react";
import Store from "../observerstore/Store";
import UserSelectContainer from "./UserSelectContainer";
const $ = Store.data;

interface Props {}
interface State {
	type: number;
}

export default class ProductCollectionContainer extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			type: 0,
		};
	}

	componentDidMount() {
		Store.attach(this);
	}

	update() {
		this.setStateNStore({
			type: $.url.type,
		});
	}

	getTitle() {
		switch (this.state.type) {
			case 0:
				return "Alle Produkte";
			case 1:
				return "Auktions-Produkte";
			default:
				return "Shop-Produkte";
		}
	}

	render() {
		return (
			<div className="container tabelle">
				<code className="component_info">
					ProductCollectionContainer.js
				</code>
				<h1>{this.getTitle()}</h1>
				<hr />
				{this.state.type === 1 && <ProductSelectContainer />}
				{$.user.root !== 0 && (
					<div>
						<UserSelectContainer />
					</div>
				)}
				<div className="row">
					<div className="col-lg-6">
						<ProductSearchContainer />
					</div>
					<div className="col-lg-6">
						<ProductSwitchContainer />
					</div>
				</div>

				{false && <Error />}
				<br />
				{this.state.type === 2 && <XmlDownloadButton />}
				<ProductCollectionTable />
				<Pagina />
			</div>
		);
	}
}
