import React from "react";
import Form from "react-bootstrap/Form";
import Product from "../models/Product";
import Observer from "../observerstore/Observer";
import Store from "../observerstore/Store";
const $ = Store.data;

type Props = {};

type State = {
	product: Product;
};

export default class TypeSelectContainer extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			product: new Product(),
		};
		this.handleChange = this.handleChange.bind(this);
	}

	update() {
		this.setStateNStoreForce({
			product: $.product,
		});
	}

	handleChange(event: any) {
		const value: number = Number(event.target.value);
		$.product.type = value;
		this.update();
	}

	render() {
		return (
			<>
				<code className="component_info">{this.constructor.name}</code>
				<Form.Group>
					<Form.Control
						as="select"
						onChange={this.handleChange}
						value={String(this.state.product.type)}
					>
						<option value={0}>Undefiniert</option>
						<option value={1}>Auktion</option>
						<option value={2}>Shop</option>
					</Form.Control>
				</Form.Group>
			</>
		);
	}
}
