import React from "react";
import Form from "react-bootstrap/Form";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
const $ = Store.data;

interface Props {}
interface State {
	sold: number;
}

export default class ProductSwitchContainer extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			sold: 2
		};
		this.search = this.search.bind(this);
	}

	componentDidMount() {
		Store.attach(this);
	}

	update() {
		this.setStateNStore({
			sold: $.search.sold
		});
	}

	search(event: any) {
		$.search.sold = Number(event.target.value);
		$.productCollection.readApi(
			Store,
			$.url.anzahl,
			1,
			$.search.searchTerm,
			$.search.auctionId,
			$.search.sold,
			$.search.userId,
			$.search.sortColumn,
			$.search.sortOrder,
			$.url.type,
		);
	}

	render() {
		return (
			<>
				<code className="component_info">{this.constructor.name}</code>
				<Form.Group controlId="auctionSelect">
					<Form.Control
						as="select"
						onChange={this.search}
						value={String(this.state.sold)}
					>
						<option value={2}>Verfügbare und verkaufte Produkte</option>
						<option value={0}>Verfügbare Produkte</option>
						<option value={1}>Verkaufte Produkte</option>
					</Form.Control>
				</Form.Group>
			</>
		);
	}
}
