import React from "react";
import { RouteComponentProps } from "react-router";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
import ProductCollectionContainer from "../containers/ProductCollectionContainer";
const $ = Store.data;

interface MatchParams {
	anzahl: string;
	seite: string;
	type: string;
}

interface Props extends RouteComponentProps<MatchParams> {}
interface State {
	userId: number;
}

export default class ProductCollectionView extends Observer<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			userId: $.user.id
		};
	}

	setUrl() {
		$.url.anzahl = Number(this.props.match.params.anzahl);
		$.url.seite = Number(this.props.match.params.seite);
		$.url.type = Number(this.props.match.params.type);
		if($.url.type === 2) {
			$.search.auctionId = 0;
		}
	}

	componentDidMount() {
		Store.attach(this);
		this.setUrl();
		$.productCollection.readApi(
			Store,
			$.url.anzahl,
			$.url.seite,
			$.search.searchTerm,
			$.search.auctionId,
			$.search.sold,
			$.search.userId,
			$.search.sortColumn,
			$.search.sortOrder,
			$.url.type,
		);
		$.categoryCollection.readApi(Store);
	}

	componentDidUpdate(prevProps: Props) {
		if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
			this.setUrl();
			$.productCollection.readApi(
				Store,
				Number(this.props.match.params.anzahl),
				Number(this.props.match.params.seite),
				$.search.searchTerm,
				$.search.auctionId,
				$.search.sold,
				$.search.userId,
				$.search.sortColumn,
				$.search.sortOrder,
				$.url.type,
			);
		}
	}

	update() {
		this.setStateNStore({
			userId: $.user.id
		});
	}

	render() {
		if (this.state.userId === 0) {
			$.user.checkUser(Store);
		}

		return (
			<div>
				<code className="component_info">{this.constructor.name}</code>
				{this.state.userId !== 0 && <ProductCollectionContainer />}
			</div>
		);
	}
}
