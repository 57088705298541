import React from "react";
import Button from "react-bootstrap/Button";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Auction from "../models/Auction";
const $ = Store.data;
const FontAwesome = require("react-fontawesome");

interface Props {}
interface State {
	collection: Auction[];
	selectAll: boolean;
	target: any[] | null;
	selected: any[] | null;
	count: Number;
}

export default class AuctionCollectionTable extends Observer<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			selected: null,
			collection: [],
			selectAll: false,
			target: null,
			count: 0
		};
		this.select = this.select.bind(this);
		this.selectAll = this.selectAll.bind(this);
	}

	weiterleiten(id: number) {
		window.location.href = `#/auktion/${id}`;
	}

	update() {
		this.setStateNStore({
			collection: [...$.auctionCollection.collection],
			count: $.auctionCollection.getSelectionCount(),
			selectAll: !this.state.selectAll
		});
	}

	deleteAuction() {
		let auctionCollection: Auction[] = $.auctionCollection.getSelectedAuctions();
		if (auctionCollection.length === 1) {
			let auctionId: number = auctionCollection[0].id;
			$.auctionCollection.deleteApi(
				Store,
				auctionId,
				$.url.anzahl,
				$.url.seite
			);
		}
	}

	sortColumn(sortcolumn: string) {
		// TODO: Implement in API and then here
		$.search.sortColumn = sortcolumn;
		$.search.sortOrder === "asc"
			? ($.search.sortOrder = "desc")
			: ($.search.sortOrder = "asc");
		$.auctionCollection.readApi(Store, $.url.anzahl, $.url.seite, "0");
	}

	selectAll() {
		const tab: any = this.refs.tabelle;
		const tabelle = tab.children;

		if (this.state.selectAll === false) {
			for (let i = 0; i < tabelle.length; i++) {
				let checkbox: any = this.refs[`checkbox${i}`];
				checkbox.checked = true;
			}
			$.auctionCollection.selectAll();
		} else {
			for (let i = 0; i < tabelle.length; i++) {
				let checkbox: any = this.refs[`checkbox${i}`];
				checkbox.checked = false;
			}
			$.auctionCollection.unselectAll();
		}
		Store.notify();
	}

	select(event: any) {
		let id = Number(event.target.id);
		$.auctionCollection.selectToggle(id);
		Store.notify();
	}

	render() {
		return (
			<React.Fragment>
				<code className="component_info">{this.constructor.name}</code>
				<div className="text-right">
					{this.state.count === 1 && (
						<Button
							variant="outline-primary"
							onClick={this.deleteAuction}
							id="buttonDelete"
						>
							<FontAwesome name="trash" /> Löschen
						</Button>
					)}
					{this.state.count > 1 && (
						<Button
							variant="outline-primary"
							href="#/stappelverarbeitung"
							id="buttonEditMultiple"
						>
							Mehrere Bearbeiten
						</Button>
					)}
				</div>
				<Table bordered hover size="sm">
					<thead>
						<tr>
							<th onClick={() => this.sortColumn("id")}>ID</th>
							<th onClick={() => this.sortColumn("name")}>
								Name
							</th>
							<th onClick={() => this.sortColumn("start")}>
								Beginn
							</th>
							<th onClick={() => this.sortColumn("ende")}>
								Ende
							</th>
							<th onClick={() => this.sortColumn("created")}>
								Erstellungsdatum
							</th>
							<th className="text-center">
								<Form.Check
									type="checkbox"
									ref={"selectAllButton" as any}
									id="checkboxSelectAll"
									onClick={this.selectAll}
								/>
							</th>
						</tr>
					</thead>
					<tbody ref="tabelle" id="tableResults">
						{this.state.collection.map((o: Auction, i: number) => (
							<tr
								key={i}
								ref={`reihe${i}`}
								className={o.selected ? "auswahl" : ""}
							>
								<td onClick={() => this.weiterleiten(o.id)}>
									{o.id}
								</td>
								<td onClick={() => this.weiterleiten(o.id)}>
									{o.name}
								</td>
								<td onClick={() => this.weiterleiten(o.id)}>
									{o.start}
								</td>
								<td onClick={() => this.weiterleiten(o.id)}>
									{o.ende}
								</td>
								<td onClick={() => this.weiterleiten(o.id)}>
									{o.created}
								</td>
								<td className="text-center">
									<Form.Check
										type="checkbox"
										ref={String("checkbox" + i) as any}
										id={String(o.id)}
										onClick={this.select}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</React.Fragment>
		);
	}
}
