import React from "react";
import Form from "react-bootstrap/Form";

class AllHauserAdditions extends React.Component {
	render() {
		return (
  <Form.Control as="select">
    <option value="0">Keine</option>
    <option value="FW">FW</option>
    <option value="S">S</option>
    <option value="J">J</option>
    <option value="B">B</option>
    <option value="F">F</option>
    <option value="IKW">IKW</option>
    <option value="DFB">DFB</option>
    <option value="SDN">SDN</option>
    <option value="BIT">BIT</option>
    <option value="BIE">BIE</option>
    <option value="OMS">OMS</option>
    <option value="ONU">ONU</option>
    <option value="OIR">OIR</option>
    <option value="OMM">OMM</option>
    <option value="UPU">UPU</option>
    <option value="UIT">UIT</option>
    <option value="OMPI">OMPI</option>
    <option value="IOK">IOK</option>
    <option value="P">P</option>
    <option value="PF">PF</option>
    <option value="T">T</option>
    <option value="KO">KO</option>
  </Form.Control>
		);
	}
}

export default AllHauserAdditions;
