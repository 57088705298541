import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Group from "../models/Group";
import GroupPrice from "../components/GroupPrice";
import Error from "./Error";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
const $ = Store.data;

interface Props {}
interface State {
	group: Group;
}

export default class GroupDetail extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			group: new Group()
		};
		this.handleChangeText = this.handleChangeText.bind(this);
		this.takeover = this.takeover.bind(this);
	}

	update() {
		let group = Object.assign({}, $.group);
		this.setStateNStoreForce({
			group: group
		});
	}

	getCategory(id: number): string {
		return $.categoryCollection.getCategoryString(id);
	}

	takeover() {
		$.product.znr = $.group.satz_nr;
		$.product.znrZahl = $.group.znrZahl;
		$.product.name_de = $.group.beschrieb;
		$.product.jahr = $.group.jahr;
		$.product.kategorie_id = $.group.kategorie_id;
		$.product.beschrieb = $.group.langbeschrieb;
		if ($.group.preis !== "") {
			$.product.preis = $.group.preis;
		}
		if ($.group.katalogwert !== "") {
			$.product.katalogwert = $.group.katalogwert;
			$.product.gestempelt = $.group.gestempelt;
			$.product.postfrisch = $.group.postfrisch;
			$.product.viererBlock = $.group.viererBlock;
			$.product.brief = $.group.brief;
			$.product.ersttag = $.group.ersttag;
		}
		Store.notify();
	}

	handleChangeText<K extends keyof Group>(event: any): void {
		let value: Group[K] = event.target.value;
		let id: K = event.target.id;
		$.group[id] = value;
		Store.notify();
	}

	render() {
		let category = $.categoryCollection.getCategoryString(
			$.group.kategorie_id
		);

		return (
			<>
				<div className="source_product">
					<code className="component_info">
						{this.constructor.name}
					</code>
					{false && <Error />}
					<Form.Group controlId="hauser_zusatz">
						<Form.Label>Hauser-Zusatz</Form.Label>
						<Form.Control
							value={this.state.group.hauser_zusatz || ""}
							type="text"
							disabled
						/>
					</Form.Group>
					<Form.Group controlId="kategorie">
						<Form.Label>Kategorie</Form.Label>
						<Form.Control
							value={category || ""}
							type="text"
							disabled
						/>
					</Form.Group>
					<Form.Group
						controlId="satz_nr"
						className="no-margin-bottom"
					>
						<Form.Label>Satz-Nummer</Form.Label>
						<Form.Control
							value={this.state.group.satz_nr || ""}
							onChange={this.handleChangeText}
							type="text"
							placeholder="Text eingeben …"
						/>
					</Form.Group>
					<Form.Group controlId="znr">
						<Form.Control
							value={this.state.group.znrZahl || ""}
							onChange={this.handleChangeText}
							type="text"
							placeholder="Text eingeben …"
							className="form-control-danger"
						/>
					</Form.Group>
					<Form.Group controlId="beschrieb">
						<Form.Label>Titel (deutsch)</Form.Label>
						<Form.Control
							value={this.state.group.beschrieb || ""}
							onChange={this.handleChangeText}
							type="text"
							placeholder="Text eingeben …"
						/>
					</Form.Group>
					<Form.Group controlId="jahr">
						<Form.Label>Jahr</Form.Label>
						<Form.Control
							value={this.state.group.jahr || ""}
							onChange={this.handleChangeText}
							type="text"
							placeholder="Text eingeben …"
						/>
					</Form.Group>
					<Form.Group controlId="langbeschrieb">
						<Form.Label>Beschrieb</Form.Label>
						<Form.Control
							value={this.state.group.langbeschrieb || ""}
							onChange={this.handleChangeText}
							type="text"
							placeholder="Text eingeben …"
						/>
					</Form.Group>

					<hr />
					<GroupPrice />

					<Form.Group controlId="preis">
						<Form.Label>Verkaufspreis</Form.Label>
						<Form.Control
							value={this.state.group.preis || ""}
							onChange={this.handleChangeText}
							type="text"
							placeholder="0.00"
						/>
					</Form.Group>

					<Button
						variant="primary"
						onClick={this.takeover}
						style={{ marginRight: 5 }}
					>
						Übernehmen
					</Button>
				</div>
			</>
		);
	}
}
