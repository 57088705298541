import Observer from "../observerstore/Observer";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import React from "react";
import SourceProduct from "../models/SourceProduct";
import Price from "../interfaces/Price";
import Store from "../observerstore/Store";
const $ = Store.data;

interface Props {}
interface State {
	sourceProduct: SourceProduct;
}

export default class SourceProductPrice extends Observer<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			sourceProduct: new SourceProduct()
		};
	}

	update() {
		let sourceProduct = Object.assign({}, $.sourceProduct);
		this.setStateNStoreForce({
			sourceProduct: sourceProduct
		});
	}

	handleChangeRadio<K extends keyof Price>(event: any): void {
		let value: Price[K] = event.target.value;
		let id: K = event.target.id;
		$.sourceProduct.setPrice(id);
		$.sourceProduct.katalogwert = value;
	}

	render() {
		return (
			<React.Fragment>
				<code className="component_info">{this.constructor.name}</code>
				<hr />
				<Form onChange={this.handleChangeRadio} className="radio_great">
					<Form.Row>
						<Col>
							<Form.Check
								type="radio"
								label={
									"Gestempelt, " +
									this.state.sourceProduct.prices.gestempelt
								}
								name="zustand"
								id="gestempelt"
								value={
									this.state.sourceProduct.prices.gestempelt
								}
							/>
							<Form.Check
								type="radio"
								label={
									"Postfrisch, " +
									this.state.sourceProduct.prices.postfrisch
								}
								name="zustand"
								id="postfrisch"
								value={
									this.state.sourceProduct.prices.postfrisch
								}
							/>
							<Form.Check
								type="radio"
								label={
									"Ungebraucht, " +
									this.state.sourceProduct.prices.ungestempelt
								}
								name="zustand"
								id="ungestempelt"
								value={
									this.state.sourceProduct.prices.ungestempelt
								}
							/>
							<Form.Check
								type="radio"
								label={
									"Brief, " +
									this.state.sourceProduct.prices.brief
								}
								name="zustand"
								id="brief"
								value={this.state.sourceProduct.prices.brief}
							/>
							<Form.Check
								type="radio"
								label={
									"FDC, " +
									this.state.sourceProduct.prices.fdc
								}
								name="zustand"
								id="fdc"
								value={this.state.sourceProduct.prices.fdc}
							/>
						</Col>
						<Col>
							<Form.Check
								type="radio"
								label={
									"Viererblock Gestempelt, " +
									this.state.sourceProduct.prices
										.gestempeltViererblock
								}
								name="zustand"
								id="gestempeltViererblock"
								value={
									this.state.sourceProduct.prices
										.gestempeltViererblock
								}
							/>
							<Form.Check
								type="radio"
								label={
									"Viererblock Postfrisch, " +
									this.state.sourceProduct.prices
										.postfrischViererblock
								}
								name="zustand"
								id="postfrischViererblock"
								value={
									this.state.sourceProduct.prices
										.postfrischViererblock
								}
							/>
							<Form.Check
								type="radio"
								label={
									"Vollstempel ET, " +
									this.state.sourceProduct.prices
										.vollstempelEt
								}
								name="zustand"
								id="vollstempelEt"
								value={
									this.state.sourceProduct.prices
										.vollstempelEt
								}
							/>
							<Form.Check
								type="radio"
								label={
									"Viererblock ET, " +
									this.state.sourceProduct.prices
										.viererblockEt
								}
								name="zustand"
								id="viererblockEt"
								value={
									this.state.sourceProduct.prices
										.viererblockEt
								}
							/>
						</Col>
					</Form.Row>
				</Form>
				<hr />
			</React.Fragment>
		);
	}
}
