import React from "react";
import Form from "react-bootstrap/Form";
import User from "../models/User";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
const $ = Store.data;

interface Props {}
interface State {
	collection: User[];
	userId: number;
}

export default class UserSelectContainer extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			collection: [],
			userId: 0
		};
		this.search = this.search.bind(this);
	}

	componentDidMount() {
		Store.attach(this);
		$.userCollection.readApi(Store);
	}

	update() {
		let collection = Object.assign([], $.userCollection.collection);
		this.setStateNStore({
			collection: collection,
			userId: $.search.userId
		});
	}

	search(event: any) {
		$.search.userId = Number(event.target.value);
		$.productCollection.readApi(
			Store,
			$.url.anzahl,
			1,
			$.search.searchTerm,
			$.search.auctionId,
			$.search.sold,
			$.search.userId,
			"id",
			"desc",
			$.url.type,
		);
	}

	render() {
		return (
			<>
				<code className="component_info">{this.constructor.name}</code>
				<Form.Group controlId="auctionSelect">
					<Form.Control
						as="select"
						onChange={this.search}
						value={String(this.state.userId)}
					>
						<option value={0}>Alle User</option>
						{this.state.collection.map((o, i) => {
							return (
								<option key={i} value={o.id}>
									{`${o.vorname} ${o.nachname}`}
								</option>
							);
						})}
					</Form.Control>
				</Form.Group>
			</>
		);
	}
}
