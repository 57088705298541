import React from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import Navigation from "./components/Navigation";
import Messages from "./components/Messages";
import Footer from "./components/Footer";
import ProductCollectionView from "./views/ProductCollectionView";
import LoginView from "./views/LoginView";
import ProductView from "./views/ProductView";
import ProductsView from "./views/ProductsView";
import AuctionView from "./views/AuctionView";
import AuctionCollectionView from "./views/AuctionCollectionView";
import LogoutContainer from "./containers/LogoutContainer";
import AuctionCreaterView from "./views/AuctionCreaterView";

function App() {
	return (
  <>
    <Router>
      <div>
        <Navigation />
		<Messages />
        <Route exact path="/" component={LoginView} />
        <Route
          exact
          path="/produktuebersicht/:anzahl/:seite"
          component={ProductCollectionView}
        />
        <Route
          exact
          path="/produktuebersicht/:type/:anzahl/:seite"
          component={ProductCollectionView}
        />
        <Route exact path="/produkt/:id" component={ProductView} />
        <Route
          exact
          path="/auktionuebersicht/:anzahl/:seite"
          component={AuctionCollectionView}
        />
        <Route exact path="/auktion/:id" component={AuctionView} />
        <Route
          exact
          path="/auktionerstellen"
          component={AuctionCreaterView}
        />
        <Route
          exact
          path="/stappelverarbeitung"
          component={ProductsView}
        />
        <Route exact path="/logout" component={LogoutContainer} />
        <Footer />
      </div>
    </Router>
  </>
	);
}

export default App;
