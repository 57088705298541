import React from "react";
import Alert from "react-bootstrap/Alert";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
const $ = Store.data;

interface Props {}
interface State {
	alertGood: boolean;
	alertBad: boolean;
}

export default class Messages extends Observer<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			alertGood: false,
			alertBad: false
		};
	}

	update() {
		let newParams = {
			alertGood: $.alertGood,
			alertBad: $.alertBad
		};
		let newState: State = Object.assign({}, this.state, newParams);
		if(JSON.stringify(this.state) !== JSON.stringify(newState)) {
			console.log('Update: %c' + this.constructor.name, 'color: cyan;');
			this.setState(newState);
			this.alarm();
		};
	}

	alarm() {
		this.scrollTop();
		setTimeout(() => {
			$.alertGood = false;
			$.alertBad = false;
			this.setState({
				alertGood: false,
				alertBad: false
			});
		}, 2500);
	}

	scrollTop() {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}

	render() {
		return (
			<>
				<Alert variant="success" show={this.state.alertGood} className="alert-message">
				Produkt wurde gesichert.
				</Alert>
				<Alert variant="danger" show={this.state.alertBad} className="alert-message">
					Login-Daten sind nicht korrekt
				</Alert>
			</>
		);
	}
}
