export const BASISURL = "http://192.168.100.236/cms";


// TEST
//export const APIURL = "https://api-test.rj-shop.ch";
//export const REQUESTURL = `${APIURL}/`;

// TEST2 Docker
//export const APIURL = "http://localhost/api/public";
//export const REQUESTURL = `${APIURL}/`;

// SCHARF
export const APIURL = "https://api.rj-shop.ch";
export const REQUESTURL = `${APIURL}/`;
